<template>

</template>

<script>
var that
export default {
  mounted() {
  },
  created() {
    that = this
    that.getUrl();
  },
  methods:{
    getUrl(){
      that.$reqGet("/user/getWorldUrl").then(res=>{
        window.open(res.data.data, '_blank');
      })
    }
  }
}
</script>
