import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './components/Login'
import Home from './components/Home'
import Test from './components/Test'
import User from './components/User'
import AdminUserList from './components/admin/AdminUserList'
import AdminUserFlow from './components/admin/AdminUserFlow'
import AdminUserOrderRecord from './components/admin/AdminUserOrderRecord'

import UserFlow from './components/UserFlow'

import OrderRecord from './components/OrderRecord'
import BuyAmount from './components/BuyAmount'
import AdminMenuInfo from './components/admin/AdminMenuInfo'

import InviteList  from "@/components/inivte/InviteList";
import InviteAmountList  from "@/components/inivte/InviteAmountList";

import  AdminSystemSettings from "@/components/admin/AdminSystemSettings";

import AdminI18n  from "@/components/admin/AdminI18n";

import UseWorld  from "@/components/UseWorld";
import UserAppList  from "@/components/UserAppList";
import AdminUserAppList  from "@/components/admin/AdminUserAppList.vue";
import AppDownRecord  from "@/components/AppDownRecord.vue";
import AdminAppDownRecord  from "@/components/admin/AdminAppDownRecord.vue";
import AdminAppleAccountList  from "@/components/admin/AdminAppleAccountList.vue";
import AdminUserInivte from "@/components/admin/AdminUserInivte.vue";
import AppDownRecordNoReview from "@/components/AppDownRecordNoReview.vue";
import UserAppListNoReview from "@/components/UserAppListNoReview.vue";

import AdminAppDownRecordReview from "@/components/admin/AdminAppDownRecordReview.vue";
import AdminUserAppListReview from "@/components/admin/AdminUserAppListReview.vue";

import AdminWithdrawApple  from "@/components/admin/AdminWithdrawApple";
import WithdrawApple  from "@/components/inivte/WithdrawApple";


Vue.use(VueRouter)

  const routes = [

]

const router = new VueRouter({
  routes: [
    { path: '/Login', component : Login},
    { path: '/', redirect: '/Login'},
    {
      path: '/Home',
      component : Home,
      redirect: '/User',
      children: [
          { path: '/User', component : User},
          { path: '/AdminMenuInfo', component : AdminMenuInfo},
          { path: '/AdminSystemSettings', component : AdminSystemSettings},
          { path: '/Test', component : Test},
          { path: '/AdminUserList', component : AdminUserList},
          { path: '/UserFlow', component : UserFlow},
          { path: '/OrderRecord', component : OrderRecord},
          { path: '/BuyAmount', component : BuyAmount},
          { path: '/AdminUserFlow', component : AdminUserFlow},
          { path: '/AdminUserOrderRecord', component : AdminUserOrderRecord},
            { path: '/InviteList', component : InviteList},
            { path: '/InviteAmountList', component : InviteAmountList},
            { path: '/AdminI18n', component : AdminI18n},
            { path: '/UseWorld', component : UseWorld},
            { path: '/AdminUserAppList', component : AdminUserAppList},
            { path: '/UserAppList', component : UserAppList},
            { path: '/AppDownRecord', component : AppDownRecord},
            { path: '/AdminAppDownRecord', component : AdminAppDownRecord},
            { path: '/AdminAppleAccountList', component : AdminAppleAccountList},
            { path: '/AdminUserInivte', component : AdminUserInivte},
            { path: '/AppDownRecordNoReview', component : AppDownRecordNoReview},
            { path: '/UserAppListNoReview', component : UserAppListNoReview},
            { path: '/AdminAppDownRecordReview', component : AdminAppDownRecordReview},
            { path: '/AdminUserAppListReview', component : AdminUserAppListReview},
              { path: '/AdminWithdrawApple', component : AdminWithdrawApple},
              { path: '/WithdrawApple', component : WithdrawApple},
      ]
    }
  ]
})

export default router
