<template>
  <div >

    <el-button style="margin-right: 20px;margin-bottom: 20px"  type="success"  @click="createApp.dig = true ">{{$t('创建APP')}}</el-button>
    <el-button style="margin-right: 5px;margin-bottom: 20px"  type="success"  @click="getInfo">{{$t('刷新列表')}}</el-button>


    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="acId"
          :label="$t('appId')">
      </el-table-column>
      <el-table-column
          prop="appId"
          :label="$t('ios_app_id')">
      </el-table-column>
      <el-table-column
          prop="icon"
          :label="$t('图标')">
        <template slot-scope="scope">
          <img v-if="scope.row.icon !== ''" :src="scope.row.icon" style="width: 50px;height: 50px">
        </template>
      </el-table-column>
      <el-table-column
          prop="appName"
          :label="$t('APP名称')">
      </el-table-column>
      <el-table-column
          prop="identifier"
          :label="$t('包名')">
      </el-table-column>
      <el-table-column
          prop="disUrl"
          :label="$t('分发链接')">
        <template slot-scope="scope">
          <el-button style="display: block;margin: 0 auto"  @click="copy(scope.row.disUrl)"   type="text">复制地址</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="androidId"
          :label="$t('安卓')">
        <template slot-scope="scope">
          <el-button v-if="scope.row.androidId === 0 " type="text" @click="uploadApk(scope.row.acId)" size="small">{{$t('上传')}}</el-button>
          <el-button v-if="scope.row.androidId !== 0 " type="text" @click="uploadApk(scope.row.acId)" size="small">{{$t('更新')}}</el-button>
        </template>
      </el-table-column>

      <el-table-column
          prop="appleAccountId"
          :label="$t('IOS')">
        <template slot-scope="scope">
          <el-button  v-if="scope.row.iosId === 1" type="text" @click="catIosList(scope.row.iosAppList,scope.row.iosId,scope.row.acId)" size="small">{{$t('更新')}}</el-button>
          <el-button v-if="scope.row.iosId === 0" type="text" @click="catIosList(scope.row.iosAppList,scope.row.iosId,scope.row.acId)" size="small">{{$t('上传')}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="tfTestInfo"
          :label="$t('TF测试信息')">
      </el-table-column>
      <el-table-column
          :label="$t('IOS预备量')">
        <template slot-scope="scope">
          {{scope.row.tfEmail.length}}
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('今日下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.dayCount + '/' + scope.row.dayAndroidCount}}
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('昨日下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.lastDayCount + '/' + scope.row.lastAndroidDayCount}}
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('总下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.sumCount + '/' + scope.row.sumAndroidCount}}
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('创建时间')">
      </el-table-column>
      <el-table-column
          prop="updateTime"
          :label="$t('更新时间')">
      </el-table-column>
      <el-table-column
          :label="$t('状态')">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1" >{{$t('等待上传')}}</span>
          <span v-if="scope.row.status === 2" >{{$t('等待管理员审核')}}</span>
          <span v-if="scope.row.status === 3" >{{$t('上架中')}}</span>
          <span v-if="scope.row.status === 4" >{{$t('下架')}}</span>
          <span v-if="scope.row.status === 5" >{{$t('管理员下架')}}</span>
<!--          <span v-if="scope.row.status === 5" >{{$t('已删除')}}</span>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="lang"
          :label="$t('默认语言')">
      </el-table-column>
      <el-table-column
          prop="errMsg"
          :label="$t('错误信息')">
      </el-table-column>
      <el-table-column
          prop="adminRemark"
          :label="$t('管理员留言')">
      </el-table-column>
      <el-table-column
          fixed="right"
          :label="$t('操作')"
          width="180">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 3 || scope.row.status === 4" type="text" @click="updateApp(scope.row)" size="small">{{$t('编辑')}}</el-button>
          <el-button type="text" @click="outputCert(scope.row)" size="small">{{$t('导出证书')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>

    <el-dialog
        :title="$t('编辑APP信息')"
        :visible.sync="updateAppVar.dialog"
        width="20%">

      <el-form ref="form"  label-width="80px">
        <el-form-item :label="$t('app名称')">
          <el-input :placeholder="$t('app名称')" v-model="updateAppVar.appName"></el-input>
          <el-switch
              v-model="isTfName"
              active-text="修改TF名称" style="margin-top: 10px">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('TF测试信息')">
          <el-input type="textarea" autosize :placeholder="$t('TF测试信息')" v-model="updateAppVar.tfTestInfo"></el-input>
        </el-form-item>
        <el-form-item :label="$t('默认语言')">
          <el-input :placeholder="$t('默认语言')" v-model="updateAppVar.lang"></el-input>
        </el-form-item>
        <el-form-item label="状态">

          <el-select v-model="updateAppVar.status" placeholder="请选择" >
            <el-option
                v-for="item in updateAppVar.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>


      <el-button @click="updateAppVar.dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="updateApp('')">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-dialog
        :title="$t('添加APP')"
        :visible.sync="createApp.dig"
        width="30%">

      <el-form ref="form"  label-width="80px">
        <el-form-item :label="$t('app名称')">
          <el-input :placeholder="$t('app名称')" v-model="createApp.appName"></el-input>
          <div style="color: red;">{{$t('提示： app的名称需要唯一')}}</div>
        </el-form-item>

        <el-form-item :label="$t('应用包名')">
          <el-input :placeholder="$t('应用包名')" v-model="createApp.identifier"></el-input>
          <div style="color: red;">{{$t('提示： 应用包名 一般为域名倒叙需要唯一 如 com.qq.app')}}</div>
        </el-form-item>
        <el-form-item :label="$t('权限')">
          <el-checkbox-group v-model="createApp.auth">
            <el-checkbox label="PushNotifications"></el-checkbox>
            <el-checkbox label="PersonalVPN"></el-checkbox>
            <el-checkbox label="NetworkExtensions"></el-checkbox>
          </el-checkbox-group>
          <div style="color: red;">{{$t('提示： PushNotifications 推送权限')}}</div>
          <div style="color: red;">{{$t('提示： PersonalVPN vpn权限')}}</div>
          <div style="color: red;">{{$t('提示：NetworkExtensions 网络扩展')}}</div>
        </el-form-item>
        <el-form-item :label="$t('tf测试信息')">
          <el-input :placeholder="$t('tf测试信息')" v-model="createApp.tfTestInfo"></el-input>
          <div style="color: red;">{{$t('提示：tf测试信息如果不为空 则必须要超过5个字符')}}</div>
        </el-form-item>
        <el-form-item :label="$t('签名配置')">
          <el-input :placeholder="$t('签名配置')" v-model="createApp.signConfig"></el-input>
          <div style="color: red;">{{$t('提示： 普通app请忽略 如包含vpn等子app请联系客服获取签名配置')}}</div>
        </el-form-item>
      </el-form>

      <el-button @click="createApp.dig = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="addApp()">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-dialog
        :visible.sync="uploadApkDialog"
        center
        width="30%">
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :data="uploadApkId"
                    :on-success="success"
                    class="uploadHead"
                    :on-progress="progress"
                    with-credentials
                    :headers="this.$getToken()"
                    drag
                    :action="'/tf_app_no_review/upload_apk_app?acId=' + uploadApkId"
                    name="apk"
                    accept=".apk"
                    :multiple="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过2048MB</div>
            </el-upload>
           </span>
    </el-dialog>

    <el-dialog
        :visible.sync="iosListDg"
        center
        width="60%" >
       <div style="margin-top: 20px;color: red;">{{$t('PS: 上传后可能需要半小时后才能下载')}}</div>
      <el-button type="primary"
                 style="display: block; margin: 40px auto;height: 60px; width: 400px;"   @click="uploadIpa(uploadIpaId)">{{$t('上传新版本')}}</el-button>
    </el-dialog>


    <el-dialog
        :visible.sync="uploadIpaDialog"
        center
        width="40%">
          <el-form ref="form"  label-width="200px">
            <el-form-item :label="$t('IPA文件')" >
              <input  size="small"  id="ipa" class="file"  name="fileIocn" type="file"  accept=".ipa"  @change="ipaF($event)"/>
            </el-form-item>
            <el-form-item  >
              <el-button type="primary" style="width: 400px" :disabled="isDio" :loading="isUploadLoad" @click="uploadIpa('')">{{isUploadText}}</el-button>
            </el-form-item>
            <el-form-item style="color: red" >
              {{$t('PS: 进度到达100%也需要等待一到三分钟请勿关闭本窗口')}}
            </el-form-item>
            <el-form-item  >
              <el-progress style="width: 400px" v-if="percentage === 0? false:true" :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
            </el-form-item>

          </el-form>



    </el-dialog>
  </div>
</template>

<script>
import Test from "@/components/Test.vue";

var that;
export default {
  name: 'UserList',
  components: {Test},
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      token: '',
      dialog: false,
      dialog1: false,
      tableData: [],
      addamountData:{
        account: '',
        amount: 1
      },
      selectData: {
        scale: 0,
        id: '',
        account: ''
      },
      updateAppVar: {
        acId: '',
        appName: '',
        status: 2,
        options: [{
          value: 3,
          label: '上架中'
        }, {
          value: 4,
          label: '下架'
        }
        ],
        lang: '',
        dialog: false,
        tfTestInfo: '',
      },
      uploadApkDialog: false,
      uploadApkId: 0,
      uploadIpaDialog: false,
      uploadIpaId: 0,
      test_account: '',
      test_password: '',
      test_remark: '',
      percentage: 0,
      ipaFile: '',
      iosList:[

      ],
      isUploadLoad: false,
      iosListDg: false,
      iosListId: 0,
      isDio: false,
      createApp: {
        dig: false,
        appName: '',
        identifier: '',
        auth: [],
        signConfig: '',
        tfTestInfo: '',
      },
      isUploadText: "上传",
      isTfName: false,
    }
  },
  beforeCreate() {

  },
  mounted() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    outputCert(data){
      that.$reqPost("/tf_app_no_review/outputCertNoReview?acId=" + data.acId,null).then(res=>{
        window.location.href = res.data.data
      })
    },
    progress(event, file, fileList){
      let number = Math.floor(event.percent);
      console.log(number)
      if(number === 100){
        that.$load(1,that.$t('等待服务器处理中 请稍等'))
      }
    },
    shuaxin(){
      that.$reqPost("/tf_app_no_review/getIosList?acId=" + that.uploadIpaId,null).then(res=>{
        that.iosList = res.data.data
      })
    },
    closeReview(id){
      that.$reqPost("/tf_app_no_review/closeReview?id=" + id,null).then(res=>{
        that.$message.success(that.$t('撤销成功'))
        that.$reqPost("/tf_app_no_review/getIosList?acId=" + that.uploadIpaId,null).then(res=>{
          that.iosList = res.data.data
        })

      })
    },
    addApp(){
      that.$load(1,'添加中')
      let data =  {
        dig: true,
            appName: '',
            identifier: '',
            auth: [],
            tfTestInfo: '',
            signConfig: {
              groupName: '',
              replaceFile: [],
              ziapp: [],
            }
      }

      data.appName = that.createApp.appName
      data.identifier = that.createApp.identifier
      data.auth = that.createApp.auth
      data.tfTestInfo = that.createApp.tfTestInfo


      if(that.createApp.signConfig !== ''){
        data.signConfig = JSON.parse(that.createApp.signConfig)
      }

      console.log(data.signConfig)
      that.$reqPost("/tf_app_no_review/create_app" ,data).then(res=>{
        that.getInfo()
        that.createApp.dig = false
      }).finally(()=>{
        that.$load(0)
      })
    },
    copy(url){
      var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
      oInput.value = url;    //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success('复制成功');
    },
    catIosList(iosAppList,iosId,acId){
      // that.iosListDg = true
      // that.iosList = iosAppList
      that.iosListId = iosId
      that.uploadIpaId = acId
      that.uploadIpaDialog = true

    },
    uploadIpa(id){

      this.iosListDg = false
      that.percentage = 0
      if(id === ""){
        if(this.ipaFile === ""){
          that.$message.error("请选择ipa")
        }else {
          that.isUploadLoad = true
          this.isDio = true
          that.isUploadText = "上传中..."
          let formData = new FormData();
          console.log("ipa信息")
          formData.append("ipa", this.ipaFile);
          formData.append("test_account", this.test_account);
          formData.append("test_password", this.test_password);
          formData.append("test_remark", this.test_remark);
          formData.append("acId", this.uploadIpaId);

          var config = {
            onUploadProgress:function(progressEvent){
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              that.percentage = complete;
            }
          };
          that.$http.post("/tf_app_no_review/upload_ipa_app",formData,config).then(function (result) {
            console.log(result.data)

            if(result.data.code ===  200){
              that.iosListDg = false
              that.isDio = false
              that.isUploadLoad = false
              that.$alert(that.$t('上传成功'), '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                  });
                }
              });
              that.iosListDg = false
              that.isDio = false
              that.isUploadLoad = false
              that.uploadIpaDialog = false
            }else {
              that.$alert(result.data.msg, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                  });
                  that.iosListDg = false
                  that.isDio = false
                  that.isUploadLoad = false
                  that.isUploadText = "上传"
                  // that.uploadIpaDialog = false
                }
              });
            }
          }).finally(function () {
            that.iosListDg = false
            that.isDio = false
            that.isUploadLoad = false
            that.isUploadText = "上传"
            // that.uploadIpaDialog = false
          })
        }
      }else {
        this.uploadIpaId = id
        this.uploadIpaDialog = true
      }
    },
    ipaF(event){
      console.log("触发")
      console.log(event.target.files[0])
      this.ipaFile = event.target.files[0]
    },
    uploadApk(data){
      this.uploadApkId = data
      this.uploadApkDialog = true
    },
    success(e){
      console.log("上传成功")
      that.$load(0,'')
      console.log(e)
      if (e.code === 200){
        this.$message.success(e.msg)
        this.uploadApkDialog = false
        this.uploadIpaDialog = false
        this.getInfo()
      }else {
        this.$message.error(e.msg)
      }
    },
    updateApp(data){
      if(data){
        that.updateAppVar.appName = data.appName
        that.updateAppVar.status = data.status
        that.updateAppVar.lang = data.lang
        that.updateAppVar.acId = data.acId
        that.updateAppVar.dialog = true
        that.updateAppVar.tfTestInfo = data.tfTestInfo
      }else {
        that.$load(1,'修改中...')

        that.$reqPost("/tf_app_no_review/update_app_status?" + new URLSearchParams(that.updateAppVar).toString() + "&isTfName=" + that.isTfName,null).then(res=>{
          that.$message.success(that.$t('修改成功'))
          that.getInfo()
          that.updateAppVar.dialog = false
        }).finally(()=>{
          that.$load(0)
        })
      }
    },
    updateScale(data){
      that.dialog1 = true
      that.selectData = data;
    },
    updateScaleOk(){
      that.$reqPost("/admin/setUserInvite?id=" + that.selectData.id + "&invite=" + that.selectData.scale ,{}).then(res=>{
        that.$message.success(that.$t('添加成功'))
        that.getInfo()
        that.dialog1 = false;
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/tf_app_no_review/list_app",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    },
    addamount(dialog,account){
      if(dialog){
        that.dialog = dialog
        that.addamountData.account = account
      }else {
        that.$reqPost("/admin/addAmount",{account: that.addamountData.account,amount: that.addamountData.amount}).then(res=>{
          that.$message.success(that.$t('添加成功'))
          that.dialog = false
          that.getInfo()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
