// i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
Vue.use(VueI18n)

//开始
const messages = {
    zh: {
        el:{
            pagination: {
                goto: '前往',
                pagesize: '/页',
                total: '共 {total} 条',
                pageClassifier: '页'
            },
        },
        '状态': '状态',
        '提示： 普通app请忽略 如包含vpn等子app请联系客服获取签名配置': '提示： 普通app请忽略 如包含vpn等子app请联系客服获取签名配置',
        '邀请人': '邀请人',
        '操作': '操作',
        '否': '否',
        '修改分成比例': '修改分成比例',
        '请选择': '请选择',
        '价格': '价格',
        '流水id': '流水id',
        '增加余额': '增加余额',
        '语言选择提示': '语言选择提示',
        '登录': '登录',
        '编辑账号信息': '编辑账号信息',
        '暂无数据': '暂无数据',
        '用户信息': '用户信息',
        '正在处理': '正在处理',
        '上传新版本': '上传新版本',
        '停用': '停用',
        '删除': '删除',
        '处理异常': '处理异常',
        '兑换比例': '兑换比例',
        '已禁用': '已禁用',
        '用户总数': '用户总数',
        '邀请人数': '邀请人数',
        '余额': '余额',
        '专用密码': '专用密码',
        '订单号': '订单号',
        '最后登录时间': '最后登录时间',
        '请输入': '请输入',
        '取消': '取消',
        '安卓扣除台数': '安卓扣除台数',
        '不同意': '不同意',
        '前完成支付': '前完成支付',
        '搜索': '搜索',
        '说明': '说明',
        '修改密码': '修改密码',
        '网站名称': '网站名称',
        '发送成功': '发送成功',
        'usdtApi': 'usdtApi',
        '分发链接': '分发链接',
        '更新时间': '更新时间',
        'PS: 上传后可能需要半小时后才能下载': 'PS: 上传后可能需要半小时后才能下载',
        '下载时间': '下载时间',
        '退出成功': '退出成功',
        '套餐描述': '套餐描述',
        '添加APP': '添加APP',
        '图标': '图标',
        '备注': '备注',
        '正在添加': '正在添加',
        '邮箱': '邮箱',
        '已过期': '已过期',
        '构建版本': '构建版本',
        '已删除': '已删除',
        '安卓': '安卓',
        '确定': '确定',
        '新增': '新增',
        '账号失效': '账号失效',
        '支付成功': '支付成功',
        '密码': '密码',
        'app名称': 'app名称',
        '未接受': '未接受',
        '中文': '中文',
        '充值金额': '充值金额',
        '兑换成功': '兑换成功',
        '错误信息': '错误信息',
        'usdtKey': 'usdtKey',
        '订单id': '订单id',
        '金额': '金额',
        '编辑套餐': '编辑套餐',
        '兑换台数': '兑换台数',
        '账号ID': '账号ID',
        '确认密码与密码不相同': '确认密码与密码不相同',
        '关闭订单即取消订单，如您已支付该款项，请勿关闭此订单，关闭订单有可能会导致您支付的款项无法到账，请谨慎操作，如有付款未到账情况，请联系客服处理，您确定关闭订单？': '关闭订单即取消订单，如您已支付该款项，请勿关闭此订单，关闭订单有可能会导致您支付的款项无法到账，请谨慎操作，如有付款未到账情况，请联系客服处理，您确定关闭订单？',
        '添加账号': '添加账号',
        '邮箱验证码': '邮箱验证码',
        'id': 'id',
        'PS: 如果app需要登录请务必提供测试账号密码 账号权限需要全开': 'PS: 如果app需要登录请务必提供测试账号密码 账号权限需要全开',
        '已启用': '已启用',
        '签名配置': '签名配置',
        'ip': 'ip',
        '请输入增加余额数量': '请输入增加余额数量',
        '立即兑换': '立即兑换',
        '我同意': '我同意',
        '充值数额': '充值数额',
        '佣金分成比例': '佣金分成比例',
        '更新': '更新',
        '今日下载': '今日下载',
        '提示： PersonalVPN vpn权限': '提示： PersonalVPN vpn权限',
        '套餐名称': '套餐名称',
        '总下载': '总下载',
        '上传成功请点击详情查看处理进度': '上传成功请点击详情查看处理进度',
        'IOS预备量': 'IOS预备量',
        '请输入原密码': '请输入原密码',
        '请输入验证码': '请输入验证码',
        '查看用户': '查看用户',
        '兑换中心': '兑换中心',
        '创建APP': '创建APP',
        '请输入账号': '请输入账号',
        '扣除台数': '扣除台数',
        '请输入添加数量': '请输入添加数量',
        'tf审核测试密码': 'tf审核测试密码',
        '撤销审核': '撤销审核',
        '下级列表': '下级列表',
        '修改成功_请重新登录': '修改成功_请重新登录',
        '应用包名': '应用包名',
        '是否线上版本': '是否线上版本',
        '等待服务器处理中 请稍等': '等待服务器处理中 请稍等',
        '说明： 如果产品类型为时间 则数量指的是分钟 如果产品类型为个数 则指的是几个': '说明： 如果产品类型为时间 则数量指的是分钟 如果产品类型为个数 则指的是几个',
        'PS: 等新版本通过tf审核后会自动替换为线上版本': 'PS: 等新版本通过tf审核后会自动替换为线上版本',
        '注册成功': '注册成功',
        '详情': '详情',
        '到账台数': '到账台数',
        '添加成功 ': '添加成功 ',
        '包名': '包名',
        '测试密码': '测试密码',
        '注册时间': '注册时间',
        '返佣金额': '返佣金额',
        '注册': '注册',
        '账号和密码不能为空': '账号和密码不能为空',
        '管理员': '管理员',
        '验证码': '验证码',
        '未支付': '未支付',
        '正在审核': '正在审核',
        '等待上传': '等待上传',
        '同步账号状态': '同步账号状态',
        '被邀请人': '被邀请人',
        '用户id': '用户id',
        '用户ID': '用户ID',
        '随机添加10个用户': '随机添加10个用户',
        '1USDT等于': '1USDT等于',
        '请确认密码': '请确认密码',
        '请选择订单状态': '请选择订单状态',
        '版本': '版本',
        '退出': '退出',
        '测试备注': '测试备注',
        '上架中': '上架中',
        '用户密码': '用户密码',
        '创建时间': '创建时间',
        '公告内容': '公告内容',
        '请在': '请在',
        '用户': '用户',
        '最后登录IP': '最后登录IP',
        '证书ID': '证书ID',
        '准备提交': '准备提交',
        '添加成功': '添加成功',
        '系统公告': '系统公告',
        '地址': '地址',
        '过期时间': '过期时间',
        '个': '个',
        '默认语言': '默认语言',
        '是': '是',
        '导入成功': '导入成功',
        '语言标识': '语言标识',
        '提示： 应用包名 一般为域名倒叙需要唯一 如 com.qq.app': '提示： 应用包名 一般为域名倒叙需要唯一 如 com.qq.app',
        '删除成功': '删除成功',
        '分成比例': '分成比例',
        '充值成功': '充值成功',
        '刷新': '刷新',
        '数量': '数量',
        'worldUrl': 'worldUrl',
        '佣金分成': '佣金分成',
        '上传': '上传',
        '等待审核': '等待审核',
        'tf官方链接': 'tf官方链接',
        '审核拒绝': '审核拒绝',
        'issId': 'issId',
        '批量添加国际化': '批量添加国际化',
        '用户类型': '用户类型',
        '类型': '类型',
        'APP名称': 'APP名称',
        '付款地址': '付款地址',
        '成功': '成功',
        '立即充值': '立即充值',
        '支付方式': '支付方式',
        '未上传': '未上传',
        '转账id': '转账id',
        '管理员下架': '管理员下架',
        'appId': 'appId',
        '苹果账号Id': '苹果账号Id',
        '创建支付订单中': '创建支付订单中',
        '已上传': '已上传',
        '复制成功': '复制成功',
        '兑换金额': '兑换金额',
        '审核通过': '审核通过',
        '支出': '支出',
        '订单关闭': '订单关闭',
        '推广链接': '推广链接',
        'tf审核状态': 'tf审核状态',
        '普通用户': '普通用户',
        '复制': '复制',
        'tf审核测试账号': 'tf审核测试账号',
        '当前台数': '当前台数',
        '启用': '启用',
        '提示：NetworkExtensions 网络扩展': '提示：NetworkExtensions 网络扩展',
        '端口': '端口',
        '收入': '收入',
        '知道了': '知道了',
        '上级id': '上级id',
        '产品': '产品',
        '转账金额必须和显示一致且需要在倒计时内完成转账，否则无法被系统确认！ 没有到账之前不能关闭此窗口,否则无法到账': '转账金额必须和显示一致且需要在倒计时内完成转账，否则无法被系统确认！ 没有到账之前不能关闭此窗口,否则无法到账',
        '获取中': '获取中',
        '下载ID': '下载ID',
        'usdtAppid': 'usdtAppid',
        'usdtNotifyDomain': 'usdtNotifyDomain',
        '同步成功': '同步成功',
        '邮箱邀请链接': '邮箱邀请链接',
        '下架': '下架',
        '修改成功': '修改成功',
        '关闭订单': '关闭订单',
        '保存': '保存',
        '用户账号': '用户账号',
        '充值中心': '充值中心',
        '翻译': '翻译',
        'PS: 进度到达100%也需要等待一到三分钟请勿关闭本窗口': 'PS: 进度到达100%也需要等待一到三分钟请勿关闭本窗口',
        '已接受': '已接受',
        '等待管理员审核': '等待管理员审核',
        'ios_app_id': 'ios_app_id',
        '复制链接': '复制链接',
        '编辑': '编辑',
        '测试账号': '测试账号',
        '语言选择': '语言选择',
        '上级账号': '上级账号',
        '管理员留言': '管理员留言',
        '下载默认语言': '下载默认语言',
        '分钟': '分钟',
        '权限': '权限',
        '提示': '提示',
        '返利比例': '返利比例',
        '已有账号_去登录': '已有账号_去登录',
        '编辑APP信息': '编辑APP信息',
        '请输入新密码': '请输入新密码',
        '账号': '账号',
        '请输入分成比例': '请输入分成比例',
        '提示： app的名称需要唯一': '提示： app的名称需要唯一',
        'USDT': 'USDT',
        '台数': '台数',
        '查看下级账号': '查看下级账号',
        '撤销成功': '撤销成功',
        '分佣比例': '分佣比例',
        '返佣时间': '返佣时间',
        '新增套餐': '新增套餐',
        '添加失败请重新尝试': '添加失败请重新尝试',
        '等待区块链确认中': '等待区块链确认中',
        '导入用户': '导入用户',
        '提示： PushNotifications 推送权限': '提示： PushNotifications 推送权限',
        '支付超时_订单关闭': '支付超时_订单关闭',
        '当前余额': '当前余额',
        '审核错误消息': '审核错误消息',
        '刷新列表': '刷新列表',
        '昨日下载': '昨日下载',
        '邀请时间': '邀请时间',
        '上传成功': '上传成功',
        '平台': '平台',
        '导出证书': '导出证书',
        '订单': '订单',
        'tf审核备注': 'tf审核备注',
        'IOS': 'IOS',
        '台': '台',
        'teamId': 'teamId',
        '请输入密码': '请输入密码',
        '订单状态': '订单状态',
        'IPA文件': 'IPA文件',
        'tf测试信息': 'tf测试信息',
    },
    en: {
        el: {
            pagination: {
                goto: 'GO TO',
                pagesize: '/page',
                total: 'total {total} strip',
                pageClassifier: 'page'
            },
        },
        '请输入密码': 'plase input password',
    }
}
//结束
export const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})


Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
})
