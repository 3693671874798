import { render, staticRenderFns } from "./AdminUserAppListReview.vue?vue&type=template&id=7cb06e14&scoped=true&"
import script from "./AdminUserAppListReview.vue?vue&type=script&lang=js&"
export * from "./AdminUserAppListReview.vue?vue&type=script&lang=js&"
import style0 from "./AdminUserAppListReview.vue?vue&type=style&index=0&id=7cb06e14&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb06e14",
  null
  
)

export default component.exports