<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.langCode" :placeholder="$t('语言标识')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-button type="primary" icon="el-icon-plus" @click="addI18n()">{{$t('新增')}}</el-button>


    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="iid"
          label="iId">
      </el-table-column>
      <el-table-column
          prop="langCode"
          :label="$t('语言标识')">
      </el-table-column>
      <el-table-column
          prop="langKey"
          :label="$t('中文')">
      </el-table-column>
      <el-table-column
          prop="langValue"
          :label="$t('翻译')">
      </el-table-column>

      <el-table-column
          fixed="right"
          :label="$t('操作')"
          width="180">
        <template slot-scope="scope">
          <el-button  type="text" @click="deleteById(scope.row.iid)" size="small">{{$t('删除')}}</el-button>

        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>

    <el-dialog
        :title="$t('批量添加国际化')"
        :visible.sync="dialog"
        width="60%">

      <el-button @click="addAddI18nData"  type="primary"  style="margin-bottom: 20px">{{$t('新增')}}</el-button>
      <el-button @click="addI18nOk"  type="primary"  style="margin-bottom: 20px">{{$t('保存')}}</el-button>
      <div>
        <div v-for="(item, index) in addI18nData" :key="index" style="margin-top: 5px">
          <span style="font-weight: bold;">{{$t('语言标识')}}：</span>
          <el-input style="width: 150px; margin-left: 10px; margin-right: 20px" v-model="item.langCode" :placeholder="$t('语言标识')"></el-input>
          <span style="font-weight: bold;">{{$t('中文')}}：</span>
          <el-input style="width: 150px; margin-left: 10px; margin-right: 20px" v-model="item.langKey" :placeholder="$t('中文')"></el-input>
          <span style="font-weight: bold;">{{$t('翻译')}}：</span>
          <el-input style="width: 150px; margin-left: 10px; margin-right: 20px" v-model="item.langValue" :placeholder="$t('翻译')"></el-input>
          <el-button @click="delAddI18nData(index)">{{$t('删除')}}</el-button>
        </div>
      </div>

    </el-dialog>


  </div>
</template>

<script>
var that;
export default {
  name: 'UserList',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        langCode: ''
      },
      dialog: false,
      dialog1: false,
      tableData: [],
      addamountData:{
        account: '',
        amount: 1
      },
      selectData: {
        scale: 0,
        id: '',
        account: ''
      },
      addI18nData:[
        {
          langCode: '',
          langKey: '',
          langValue: ''
        }
      ]
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    delAddI18nData(index){
      that.addI18nData.splice(index, 1);
    },
    addAddI18nData(index){
      that.addI18nData.push({
        langCode: '',
        langKey: '',
        langValue: ''
      });
    },
    deleteById(id){
      that.$reqGet("/admin/deleAdminI18nList?id=" + id,{}).then(res=>{
          that.getInfo()
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/getAdminI18nList",{page:that.page.当前页,pageSize: that.page.页大小,langCode: that.page.langCode}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    },
    addI18n(){
      that.addI18nData = [
        {
          langCode: '',
          langKey: '',
          langValue: ''
        }
      ]
      that.dialog = true
    },
    addI18nOk(){
      that.$reqPost("/admin/addAdminI18nList",{list: that.addI18nData}).then(res=>{
          that.$message.success(that.$t('添加成功'))
      })
      that.dialog = false
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
