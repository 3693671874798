<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="sid"
          :label="$t('sid')">
      </el-table-column>
      <el-table-column
          prop="userId"
          :label="$t('用户ID')">
      </el-table-column>
      <el-table-column
          prop="account"
          :label="$t('用户账号')">
      </el-table-column>
      <el-table-column
          prop="amount"
          :label="$t('提现金额')">
      </el-table-column>
      <el-table-column
          prop="userRemark"
          :label="$t('备注')">
      </el-table-column>
      <el-table-column
          prop="remark"
          :label="$t('管理备注')">
      </el-table-column>
      <el-table-column
          :label="$t('提现状态')">
        <template slot-scope="scope">
          <div v-if="scope.row.status == '申请中'">{{$t('申请中')}}</div>
          <div v-if="scope.row.status == '已提现'">{{$t('已提现')}}</div>
          <div v-if="scope.row.status == '已拒绝'">{{$t('已拒绝')}}</div>
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('操作')">
        <template slot-scope="scope">
          <el-button @click="withdrawApple(scope.row.sid)" type="text"  size="small">{{$t('处理')}}</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-dialog
        :title="$t('处理')"
        :visible.sync="dialog"
        width="20%">

      <el-form ref="form"  label-width="100px">

        <el-form-item  :label="$t('状态')">
          <el-select v-model="hand.status" placeholder="请选择" >
            <el-option
                v-for="item in hand.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item :label="$t('管理员留言')">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('管理员留言')"
              v-model="hand.remark"
              ></el-input>
        </el-form-item>
        <el-form-item :label="$t('提示')">
          <div >{{$t('如果状态设置为已拒绝 佣金会被退回用户佣金余额')}}</div>
        </el-form-item>

      </el-form>

      <el-button @click="dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="withdrawApple('')">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


  </div>
</template>

<script>
var that;
export default {
  name: 'UserFlow',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      dialog: false,
      tableData: [],
      addBalanceData:{
        account: '',
        balance: 1
      },
      hand: {
        status: '',
        sid: '',
        remark: '',
        options: [{
          value: '申请中',
          label: '申请中'
        }, {
          value: '已提现',
          label: '已提现'
        }, {
          value: '已拒绝',
          label: '已拒绝'
        }
        ],
        lang: '',
        dialog: true
      },
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    withdrawApple(data){
      if(data !== ''){
        console.log("弹出" + data)
        that.dialog = true;
        that.hand.sid = data;
      }else {
        console.log("不弹出")
        that.$reqPost('/admin/hand_withdraw?remark=' + that.hand.remark + "&sId=" + that.hand.sid + "&status=" + that.hand.status ,'').then(res=>{
          that.$message.success(that.$t('处理成功'));
          that.dialog = false;
          that.getInfo()
        })
      }

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/withdraw_list",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
