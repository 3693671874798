<template>
    <div>
        <!--        居中盒子-->
        <div class="center_box">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{$t('充值中心')}}</span>
                </div>
                <div class="text item">
                  {{$t('余额')}}:  {{count}} USDT
                </div>
                <div class="text item">
                  {{$t('充值数额')}}:  <el-input-number style="margin-left: 10px"  v-model="buyCount"  :min="1" :max="10000" :label="$t('请输入')"></el-input-number>
                </div>
               <div class="text item">
                 {{$t('支付方式')}}:   <el-radio style="margin-left: 10px"  v-model="radio" label="1">USDT</el-radio>
                </div>

                <el-button style="margin-top: 10px" type="primary" @click="buy()">{{$t('立即充值')}}</el-button>
            </el-card>


              <el-dialog :show-close="false"  :title="orderValue" width="30%" :visible.sync="dialogVisible">



                <div style="text-align: center"><h1 STYLE="color: red">{{$t('充值金额')}} {{buyCount}} USDT</h1></div>

                <div style="color: red">{{$t('转账金额必须和显示一致且需要在倒计时内完成转账，否则无法被系统确认！ 没有到账之前不能关闭此窗口,否则无法到账')}}</div>
                <div style="text-align: center"> <el-image :src="or"></el-image></div>

                <div>

                  <el-tag style="border-radius: 0">USDT-TRC20{{$t('数量')}}</el-tag>

                  <el-tag style="width: 320px;margin-left: 5px;border-radius: 0;color: black" >{{usdt}}</el-tag>

                  <el-button size="mini" style="display: inline;margin-left: 5px;border-radius: 0;width: 80px " @click="copy(usdt)">{{$t('复制')}}</el-button>
                </div>
                <div>
                  <el-tag style="border-radius: 0">USDT-TRC20{{$t('地址')}}</el-tag>
                  <el-tag style="width: 320px;margin-left: 5px;border-radius: 0;color: black" >{{usdt_addr}}</el-tag>

                  <el-button size="mini" style="display: inline;margin-left: 5px;width: 80px" @click="copy(usdt_addr)">{{$t('复制')}}</el-button>

                </div>

                <div style="color: red;margin-top: 10px;text-align: center">{{$t('请在')}}{{outTime}}{{$t('前完成支付')}}</div>

                <el-button  style="width: 100%;border-radius: 0;margin-top: 20px" type="success" :loading="load">{{loadValue}}</el-button>

               <div>

                 <el-popconfirm
                     @confirm="ok"
                     :title="$t('关闭订单即取消订单，如您已支付该款项，请勿关闭此订单，关闭订单有可能会导致您支付的款项无法到账，请谨慎操作，如有付款未到账情况，请联系客服处理，您确定关闭订单？')"
                 >
                   <el-button type="danger" style="width: 100%;border-radius: 0;margin-top: 20px" slot="reference">{{$t('关闭订单')}}</el-button>
                 </el-popconfirm>
               </div>


              </el-dialog>

        </div>

    </div>
</template>

<script>
    var that;
    var queryOderV
    export default {
        created () {
            that = this
            that.orderValue = that.$t('订单')
            that.loadValue = that.$t('等待区块链确认中')
            that.sum = that.$t('获取中')
            that.account = that.$t('获取中')
            that.type = that.$t('获取中')
            that.count = that.$t('获取中')
            that.getInfo()
        },
        name: 'BuyAmount',
        data () {
            return {
              orderValue: '订单',
              loadValue: '等待区块链确认中',
              load: true,
              orderId: '',
              outTime: '',
              usdt_addr: '',
              usdt: '',
              or:'',
              url: '',
             dialogVisible: false,
              radio: '1',
              buyCount: 1,
              sum: '获取中',
              account: '获取中',
              type: '获取中',
              count: '获取中',
              dialogFormVisible: false,
              password: '',
              newPassword: '',
              formLabelWidth: '120px',
              req:{
                mdmSoftNum: 0,
                mdmSoftReCount: 0,
                mdmSuperNum: 0,
                mdmSuperReCount: 0,
                superNum: 0,
                superReCount: 0,
                softNum: 0,
                softReCount: 0,
                superTotal: 0,
                mdmSuperTotal: 0,
                softTotal: 0,
                mdmSoftTotal: 0,
                webPackTotal: 0
              }
            }
        },

      watch: {
        dialogVisible: function (newValue, oldValue) {
          if(!newValue){
            clearInterval(queryOderV)
          }
        }
      },
        methods: {

          ok(){
            that.dialogVisible = false
            console.log("确定")
          },

          getInfo(){

            that.$reqGet("/user/getUserInfo").then(res=>{
              if(res.data.code === 200){
                var user = res.data.data.user
                that.count = user.amount
              }else {
                that.$message.error(res.data.message)
              }
            })

          },
          queryOrder(){

            that.$reqGet('/order/query_order?orderId=' + this.orderId).then(res=>{
              if(res.data.data.status === 2){
                that.load = false
                that.loadValue = that.$t('支付成功')
                that.dialogVisible = false
                that.$message.success( that.$t('充值成功'))
                that.getInfo()
                clearInterval(queryOderV)
              }else if(res.data.data.status === 3){
                that.$message.error( that.$t('支付超时_订单关闭'))
                that.dialogVisible = false
                clearInterval(queryOderV)
              }
            })

          },
          copy(url){
            var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
            oInput.value = url;    //赋值
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = 'oInput';
            oInput.style.display = 'none';
            this.$message.success(that.$t('复制成功'));
          },
          buy(){
            clearInterval(queryOderV)
            that.$load(1,that.$t('创建支付订单中'))

            that.$reqGet('/order/create_order?amount=' + this.buyCount).then(result=>{
              that.usdt = result.data.data.money

              that.usdt_addr = result.data.data.usdtdz

              that.or = result.data.data.qr
              that.outTime = result.data.data.timeout
              that.orderId = result.data.data.ddh

              that.orderValue = result.data.data.starttime
              that.dialogVisible = true

              that.load = true
              that.loadValue = that.$t('等待区块链确认中')

              queryOderV = setInterval(function() {
                // 执行请求的代码
                that.queryOrder()
              }, 5000);
            }).finally(()=>{
              that.$load(0)
            })


          }
        }
    }
</script>

<style scoped>
    .center_box{

    }



    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }

    .box-card {
        width: 480px;
    }


</style>
