<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>


    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="id"
          :label="$t('用户id')">
      </el-table-column>
      <el-table-column
          prop="account"
          :label="$t('用户账号')">
      </el-table-column>
      <el-table-column
          prop="password"
          :label="$t('用户密码')">
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('注册时间')">
      </el-table-column>
      <el-table-column
          prop="lastLoginTime"
          :label="$t('最后登录时间')">
      </el-table-column>
      <el-table-column
          prop="amount"
          :label="$t('余额') + '/USDT'">
        <template slot-scope="scope">
          <span>{{scope.row.amount}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="scale"
          :label="$t('分成比例')">
        <template slot-scope="scope">
          <span>{{scope.row.scale}}%</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          :label="$t('操作')"
          width="180">
        <template slot-scope="scope">
          <el-button  type="text" @click="addamount(true,scope.row.account)" size="small">{{$t('增加余额')}}</el-button>
          <el-button  type="text" @click="updateScale(scope.row)" size="small">{{$t('修改分成比例')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>

    <el-dialog
        :title="$t('请输入增加余额数量')"
        :visible.sync="dialog"
        width="20%">
      <el-input :placeholder="$t('请输入添加数量')" v-model="addamountData.amount"></el-input>
      <el-button @click="dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="addamount()">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-dialog
        :title="$t('请输入分成比例')"
        :visible.sync="dialog1"
        width="20%">
      <el-input :placeholder="$t('请输入添加数量')" v-model="selectData.scale"></el-input>
      <el-button @click="dialog1 = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="updateScaleOk()">{{$t('确定')}}</el-button>
    </el-dialog>

  </div>
</template>

<script>
var that;
export default {
  name: 'UserList',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      dialog: false,
      dialog1: false,
      tableData: [],
      addamountData:{
        account: '',
        amount: 1
      },
      selectData: {
        scale: 0,
        id: '',
        account: ''
      }
    }
  },
  beforeCreate() {

  },
  mounted() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    updateScale(data){
      that.dialog1 = true
      that.selectData = data;
    },
    updateScaleOk(){
      that.$reqPost("/admin/setUserInvite?id=" + that.selectData.id + "&invite=" + that.selectData.scale ,{}).then(res=>{
        that.$message.success(that.$t('添加成功'))
        that.getInfo()
        that.dialog1 = false;
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/getUserList",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    },
    addamount(dialog,account){
      if(dialog){
        that.dialog = dialog
        that.addamountData.account = account
      }else {
        that.$reqPost("/admin/addAmount",{account: that.addamountData.account,amount: that.addamountData.amount}).then(res=>{
          that.$message.success(that.$t('添加成功'))
          that.dialog = false
          that.getInfo()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
