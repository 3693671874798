import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import Qs from  'qs';
import { i18n } from './i18n.js' // 导入i18n

Vue.config.productionTip = false
import axios from 'axios'

Vue.prototype.$http = axios
Vue.prototype.$reqGet = reqGet

Vue.prototype.$reqPost = reqPost

let vueInstance = null;

const code = {
  success: 200,//成功的code
  quit: 10001 //退出的状态码
}
Vue.prototype.$getSession = getSession
Vue.prototype.$setSession = setSession

/**
 *
 * @param $key
 * @returns {string} 字符串
 */
function getSession($key){
  let value = window.localStorage.getItem($key)
  if(null === value || "undefined" === value){
    value = "";
  }
  return value;
}

/**
 * 设置存储
 * @param $key  字符串
 * @param $value 字符串
 */
function setSession($key,$value){
   window.localStorage.setItem($key,$value);
}


/**
 *
 * @param url 路径 /user/Login
 * @param data json数据
 */
function reqGet(url,data){
  let s = Qs.stringify(data);

  if(s !== ""){
    s = "?" + s;
  }

  return  axios.request({
    url:url + s,
    method:"get"
  })
}

function reqPost(url,data){


  return   axios.request({
    url:url,
    method:"post",
    data:JSON.stringify(data),
    headers:{
      'content-type':"application/json"
    }
  })
}


// 添加证书的使用教程
Vue.prototype.addCertHelp = "https://www.baidu.com/"


console.log(window.document.domain)



// 需要设置为真不然sesion会变动
axios.defaults.withCredentials = true

Vue.prototype.$load =load;


// Vue.use(ElementUI)
function load(is,msg) {
  if (is === 1) {
    this.$loading({
      lock: true,
      text: msg,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  } else {
    this.$loading({
      lock: true,
      text: msg,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    }).close();
  }
}

axios.interceptors.request.use(function (config) {
  config.headers.token = getSession("token");
  if (vueInstance) {
    config.headers.lang = vueInstance.$i18n.locale;
  }
  console.log("全局拦截token" + config.headers.token);
  return config;
});

axios.interceptors.response.use(function (config) {
  console.log(config.data)
  if (config.data.code === code.quit) {
    setSession("token","")
    Vue.prototype.$message.error(vueInstance.$t('请登录'))
    router.push('/Login')
    //不返回空会继续执行then
    // return new Promise(() => {});
  }else if(config.data.code !== code.success){

    Vue.prototype.$message(config.data.msg)

    //不返回空会继续执行then
    return Promise.reject(config);
  }

  return config
},error => {
  Vue.prototype.$message.error(vueInstance.$t('服务器错误'))
  console.log("服务器错误")

  //不返回空会继续执行then
  return Promise.reject(error);
})

Vue.prototype.$getToken = getToken;


function getToken(){

  let token = window.localStorage.getItem("token");
  console.log("获取header")

  if(null != token){
    console.log("有token")

    return {token: token}
  }
  return {}
}

// 判断登录状态
router.beforeEach((to,from,next) =>{
  console.log("全局")
  if(to.path === '/Login'){
    next()
  }else {
    if(getSession("token") !== ''){
      next()
    }else {
      Vue.prototype.$message("请登录")
      router.push('/Login')
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    vueInstance = this;
  },
}).$mount('#app')


