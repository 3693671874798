<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>

    <el-select clearable v-model="page.status" :placeholder="$t('请选择订单状态')" style="margin-left: 20px">
      <el-option :label="$t('支付成功')" value="2"></el-option>
      <el-option :label="$t('未支付')" value="1"></el-option>
      <el-option :label="$t('订单关闭')" value="3"></el-option>
    </el-select>

    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="orderId"
          :label="$t('订单id')" style="text-align: center">
      </el-table-column>
      <el-table-column
          prop="userId"
          :label="$t('用户id')" >
      </el-table-column>
      <el-table-column
          prop="account"
          :label="$t('用户账号')" >
      </el-table-column>
      <el-table-column
          prop="money"
          :label="$t('充值金额') + '/USDT'">
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('创建时间')">
      </el-table-column>
      <el-table-column
          prop="status"
          :label="$t('订单状态')">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 1">{{$t('未支付')}}</div>
          <div v-if="scope.row.status == 2">{{$t('支付成功')}}</div>
          <div v-if="scope.row.status == 3">{{$t('订单关闭')}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="fromuser"
          :label="$t('付款地址')">
      </el-table-column>
      <el-table-column
          prop="transactionId"
          :label="$t('账号')">
      </el-table-column>

    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


  </div>
</template>

<script>
var that;
export default {
  name: 'OrderRecord',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: '',
        status: ""
      },
      dialog: false,
      tableData: [],
      addBalanceData:{
        account: '',
        balance: 1
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/getAdminUserOrderRecord",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account,status: that.page.status}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
