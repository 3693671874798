<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.email" :placeholder="$t('邮箱')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-button type="primary"  @click="addAccount('')">{{$t('添加账号')}}</el-button>
    <el-button type="primary"  @click="getInfo()">{{$t('刷新列表')}}</el-button>


    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="accountId"
          :label="$t('账号ID')">
      </el-table-column>

      <el-table-column
          prop="email"
          :label="$t('邮箱')">
      </el-table-column>
      <el-table-column
          prop="password"
          :label="$t('密码')">
      </el-table-column>

      <el-table-column
          prop="authPassword"
          :label="$t('专用密码')">
      </el-table-column>
      <el-table-column
          prop="certIds"
          :label="$t('证书ID')">
      </el-table-column>


      <el-table-column
          prop="created"
          :label="$t('创建时间')">
      </el-table-column>
      <el-table-column
          prop="expire"
          :label="$t('过期时间')">
      </el-table-column>

      <el-table-column
          prop="issId"
          :label="$t('issId')">
      </el-table-column>

      <el-table-column
          :label="$t('状态')">
        <template slot-scope="scope">
          <span></span>
          <span v-if="scope.row.status === 1 " type="text" size="small">{{$t('已启用')}}</span>
          <span v-if="scope.row.status === 0 " type="text" size="small">{{$t('已禁用')}}</span>
          <span v-if="scope.row.status === -1 " type="text" size="small">{{$t('账号失效')}}</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="teamId"
          :label="$t('teamId')">
      </el-table-column>
      <el-table-column
          prop="remark"
          :label="$t('备注')">
      </el-table-column>

      <el-table-column
          fixed="right"
          :label="$t('操作')"
          width="180">
        <template slot-scope="scope">
          <el-button  type="text" @click="updateAccount(scope.row)" size="small">{{$t('编辑')}}</el-button>
          <el-button  type="text" @click="catUser(scope.row.accountId)" size="small">{{$t('查看用户')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>

    <el-dialog
        :title="$t('编辑账号信息')"
        :visible.sync="account.dig"
        width="20%">

      <el-form ref="form"  label-width="80px">
        <el-form-item :label="$t('密码')">
          <el-input :placeholder="$t('密码')" v-model="account.password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('专用密码')">
          <el-input :placeholder="$t('专用密码')" v-model="account.authPassword"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="account.status" placeholder="请选择" >
            <el-option
                v-for="item in account.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>


      <el-button @click="account.dig = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="updateAccount('')">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-dialog
        :visible.sync="userListDg"
        center
        width="60%" >
      <el-button  type="primary" @click="shuaxin()"  style="margin-bottom: 20px">{{$t('刷新')}}</el-button>
      <el-button  type="primary" @click="syUserStatus()"  style="margin-bottom: 20px">{{$t('同步账号状态')}}</el-button>

      <el-button  type="primary" disabled  style="margin-bottom: 20px">{{$t('用户总数') +  userList.length}}</el-button>
      <el-button  type="primary" disabled  style="margin-bottom: 20px">{{$t('已接受') +  count1}}</el-button>
      <el-button  type="primary" disabled  style="margin-bottom: 20px">{{$t('未接受') +  count2}}</el-button>

      <el-button  type="primary" @click="addUserList()"  style="margin-bottom: 20px;margin-left: 300px;margin-right: 10px">{{$t('随机添加用户')}}</el-button>
      <el-input-number v-model="add_num"  label="描述文字"></el-input-number>


      <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
      <el-button  type="primary" @click="triggerFileInput()"  style="margin-left: 20px">{{$t('导入用户')}}</el-button>
      <el-table
          :empty-text="$t('暂无数据')"
          stripe
          border
          header-cell-style="text-align:center"
          cell-style="text-align:center"
          :data="userList"
          height="500"
      >

        <el-table-column
            prop="uid"
            :label="$t('用户id')">
        </el-table-column>
        <el-table-column
            prop="email"
            :label="$t('邮箱')">
        </el-table-column>
        <el-table-column
            :label="$t('邮箱邀请链接')">
          <template slot-scope="scope">
            <el-button v-if="scope.row.emailUrl !== '' && scope.row.status === '未接受'" size="mini" style="display: inline;margin-left: 5px;border-radius: 0;width: 80px " @click="copy(scope.row.emailUrl)">{{$t('复制链接')}}</el-button>
          </template>

        </el-table-column>
        <el-table-column
            prop="code"
            :label="$t('邮箱验证码')">
        </el-table-column>
        <el-table-column
            prop="status"
            :label="$t('状态')">
        </el-table-column>
<!--        <el-table-column-->
<!--            fixed="right"-->
<!--            :label="$t('操作')"-->
<!--            width="180">-->
<!--        -->
<!--        </el-table-column>-->

      </el-table>

    </el-dialog>


    <el-dialog
        :title="$t('添加账号')"
        :visible.sync="addAccountVar.dig"
        width="30%">

      <el-form ref="form"  label-width="80px">
        <el-form-item :label="$t('邮箱')">
          <el-input :disabled="isEdit" :placeholder="$t('邮箱')" v-model="addAccountVar.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('密码')">
          <el-input :disabled="isEdit" :placeholder="$t('密码')" v-model="addAccountVar.pwd"></el-input>
        </el-form-item>
        <el-form-item :label="$t('专用密码')">
          <el-input :disabled="isEdit" :placeholder="$t('专用密码')" v-model="addAccountVar.authPassword"></el-input>
        </el-form-item>
        <el-radio-group  v-model="codeIndex" style="margin-left: 40px;">
          <div v-for="(value, index) in codeVar.trustedPhoneNumbers " style="margin-top: 20px" >
            <el-radio  :label="index">
              {{value.pushMode === 'sms' ?'短信':'设备验证码'}}  {{value.numberWithDialCode}}
            </el-radio>
          </div>
        </el-radio-group>

      </el-form>
     <div v-if="codeVar.trustedPhoneNumbers.length > 0">
       <el-input :placeholder="$t('请输入验证码')" v-model="smsCode" style="width: 200px;margin-right: 20px;margin-left: 40px"></el-input>
       <el-button @click="sendCodeF()" :disabled="isDis"  style="margin-top: 30px">{{codeText}}</el-button>
     </div>

      <el-button @click="addAccountVar.dig = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="addAccount('123')">{{$t('确定')}}</el-button>
    </el-dialog>
  </div>
</template>

<script>
var that;
export default {
  name: 'UserList',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: '',
        email: '',
        accountId: ''
      },
      dialog: false,
      dialog1: false,
      tableData: [],
      addamountData:{
        account: '',
        amount: 1
      },
      selectData: {
        scale: 0,
        id: '',
        account: ''
      },
      userListDg: false,
      userList:[],
      updateAppVar: {
        acId: '',
        appName: '',
        status: 2,
        options: [{
          value: 2,
          label: '等待管理员审核'
        }, {
          value: 3,
          label: '上架中'
        }, {
          value: 4,
          label: '下架'
        }, {
          value: 5,
          label: '管理员下架'
        }
        ],
        lang: '',
        dialog: false
      },
      iosList:[

      ],
      isEdit: false,
      iosListDg: false,
      iosListId: 0,
      account:{
        accountId: '',
        authPassword: "",
        password: "",
        status: "",
        dig: false,
        options: [{
          value: 1,
          label: '启用'
        }, {
          value: 0,
          label: '关闭'
        }, {
          value: -1,
          label: '账号失效'
        }
        ],
      },
      oldEmail:'',
      addAccountVar:{
        dig: false,
        email: 'smoughsou@21cn.com',
        pwd: 'Zz112211',
        authPassword: 'Zz112211'
      },
      smsCode: '',
      isDis: false,
      codeText: '发送验证码',
      codeVar: {
        hasAutoReceiveDevice: false,
        hasAutoReceivePhone: false,
        autoReceivePhone: '',
        trustedPhoneNumbers: [
          // {
          //   "id": 3,
          //   "pushMode": "sms",
          //   "numberWithDialCode": "+7 • (•••) •••-••-96"
          // },
          // {
          //   "id": 3,
          //   "pushMode": "sms",
          //   "numberWithDialCode": "+7 • (•••) •••-••-96"
          // },
          // {
          //   "id": 3,
          //   "pushMode": "sms",
          //   "numberWithDialCode": "+7 • (•••) •••-••-96"
          // }
        ]
      },
      codeIndex: 0,
      count1: 0,
      count2: 0,
      add_num: 100
    }
  },
  beforeCreate() {

  },
  mounted() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{

    triggerFileInput() {
      // 触发文件选择对话框
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
        // 重置input元素的值
        event.target.value = '';
      }
    },
    uploadFile(file) {
      that.$load(1,'处理中')
      // 创建FormData对象，将文件添加到表单数据中
      const formData = new FormData();
      formData.append("txt", file);
      formData.append("accountId", that.userId);
      // 使用axios发送POST请求，将文件上传到后端
      that.$http.post("/admin/inputUserEmail",formData).then(res=>{
        that.$message.success(that.$t('导入成功'))
        that.shuaxin()
      },errors=>{
        that.$alert(that.$t('添加失败请重新尝试'), '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
          }
        });
      }).finally(()=>{
        that.$load(0)
      })
    },
    catUser(id){
      that.userId = id
      that.userListDg = true
      that.shuaxin()
    },
    addUserList(){
      //提示输入数量
      that.$load(1,'处理中')
      that.$reqPost("/admin/addUserList?accountId=" + that.userId + "&num=" + that.add_num,null).then(res=>{
        that.$message.success(that.$t('添加成功'))
        that.shuaxin()
      },errors=>{
        that.$alert(that.$t('添加失败请重新尝试'), '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
          }
        });
      }).finally(()=>{
        that.$load(0)
      })
    },
    syUserStatus(){
      that.$load(1,'处理中')
      that.$reqPost("/admin/syUserStatus?accountId=" + that.userId,null).then(res=>{
        that.$message.success(that.$t('同步成功'))
        that.shuaxin()
      }).finally(()=>{
        that.$load(0)
      })
    },
    delAccountUser(id){
      that.$load(1,'处理中')
      that.$reqPost("/admin/deleteAccountUser?accountUserId=" + id,null).then(res=>{
        that.$message.success(that.$t('删除成功'))
        that.shuaxin()
      }).finally(()=>{
        that.$load(0)
      })
    },
    sendUser(id){
      that.$load(1,'处理中')
      that.$reqPost("/admin/sendUserInviter?uid=" + id,null).then(res=>{
        that.$message.success(that.$t('发送成功'))
        that.shuaxin()
      }).finally(()=>{
        that.$load(0)
      })
    },
    shuaxin(){
      that.$load(1,'处理中')
      that.$reqPost("/admin/getAccountUserList?accountId=" + that.userId,null).then(res=>{
        that.userList = res.data.data
        that.count1 =  0
        that.count2 = 0
        for (let i = 0; i < that.userList.length; i++) {
          that.userList[i].status === '已接受' ? that.count1 = that.count1 +1 : that.count2 = that.count2 +1
        }
      }).finally(()=>{
        that.$load(0)
      })
    },
    sendCodeF(){
      let phone = that.codeVar.trustedPhoneNumbers[that.codeIndex]
      that.$reqPost("/admin/send_code?email=" + that.addAccountVar.email + "&deviceId=" + phone.id + "&pushMode=" + phone.pushMode ,null).then(res=>{
        that.$message.success(that.$t('发送成功'))
        that.codeText = 60
        that.isDis = true
        let timer = setInterval(() => {
          that.codeText--
          if (that.codeText <= 0) {
            clearInterval(timer)
            that.codeText = '发送验证码'
            that.isDis = false
          }
        }, 1000)
      })
    },
    copy(url){
      var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
      oInput.value = url;    //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success('复制成功');
    },
    catIosList(iosAppList,iosId){
      that.iosListDg = true
      that.iosList = iosAppList
      that.iosListId = iosId
    },
    updateAccount(data){
      if(data){
        that.account.accountId = data.accountId
        that.account.authPassword = data.authPassword
        that.account.password = data.password
        that.account.status = data.status
        that.account.dig = true
      }else {
        that.$reqPost("/admin/update_apple_account?" + new URLSearchParams(that.account).toString() ,null).then(res=>{
          that.$message.success(that.$t('修改成功'))
          that.getInfo()
          that.account.dig = false
        })
      }
    },
    updateApp(data){
      if(data){
        that.updateAppVar.appName = data.appName
        that.updateAppVar.status = data.status
        that.updateAppVar.lang = data.lang
        that.updateAppVar.acId = data.acId
        that.updateAppVar.dialog = true
      }else {
        that.$reqPost("/admin/update_app_status?" + new URLSearchParams(that.updateAppVar).toString() ,null).then(res=>{
          that.$message.success(that.$t('修改成功'))
          that.getInfo()
          that.updateAppVar.dialog = false
        })
      }
    },
    updateScale(data){
      that.dialog1 = true
      that.selectData = data;
    },
    updateScaleOk(){
      that.$reqPost("/admin/setUserInvite?id=" + that.selectData.id + "&invite=" + that.selectData.scale ,{}).then(res=>{
        that.$message.success(that.$t('添加成功'))
        that.getInfo()
        that.dialog1 = false;
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/list_apple_account",{page:that.page.当前页,pageSize: that.page.页大小,email: that.page.email}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    },
    addAccount(data){
      if(data === ''){
        that.codeVar = {
          hasAutoReceiveDevice: false,
          hasAutoReceivePhone: false,
          autoReceivePhone: '',
          trustedPhoneNumbers: []
        }
        that.addAccountVar.email = ''
        that.addAccountVar.pwd = ''
        that.addAccountVar.authPassword = ''

        that.smsCode = ''
        that.isEdit = false
        that.addAccountVar.dig = true
      }else {
        //已发送
        if(that.codeVar.trustedPhoneNumbers.length > 0){
          if(that.smsCode === ''){
            that.$message.error(that.$t('请输入验证码'))
            return
          }
          that.$load(1,that.$t('正在添加'));
          that.$reqPost("/admin/verify_code?email=" +  that.addAccountVar.email + "&smsCode=" + that.smsCode  + "&deviceId=" + that.codeVar.trustedPhoneNumbers[that.codeIndex].id  + "&pushMode="  + that.codeVar.trustedPhoneNumbers[that.codeIndex].pushMode +  "&pwd=" + that.addAccountVar.pwd + "&authPwd="+ that.addAccountVar.authPassword,null).then(res=>{
            that.$message.success(that.$t('添加成功 '))
            that.getInfo()
            that.addAccountVar.dig = false
          }).finally(()=>{
            that.$load(0);
          })

        }else {
          that.$load(1,that.$t('正在添加'));
          //未发送
          that.$reqPost("/admin/add_account?email=" +  that.addAccountVar.email +  "&pwd=" + that.addAccountVar.pwd,null).then(res=>{
            that.codeVar = res.data.data
            that.isEdit  =true
          }).finally(()=>{
            that.$load(0);
          })
        }

      }
    },
    addamount(dialog,account){
      if(dialog){
        that.dialog = dialog
        that.addamountData.account = account
      }else {
        that.$reqPost("/admin/addAmount",{account: that.addamountData.account,amount: that.addamountData.amount}).then(res=>{
          that.$message.success(that.$t('添加成功'))
          that.dialog = false
          that.getInfo()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
