<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-button type="primary"  @click="getInfo()">{{$t('刷新列表')}}</el-button>

    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="acId"
          :label="$t('id')">
      </el-table-column>
      <el-table-column
          prop="appleAccountId"
          :label="$t('苹果账号Id')">
      </el-table-column>
      <el-table-column
          prop="appId"
          :label="$t('ios_app_id')">
      </el-table-column>
      <el-table-column
          prop="icon"
          :label="$t('图标')">
        <template slot-scope="scope">
          <img v-if="scope.row.icon !== ''" :src="scope.row.icon" style="width: 50px;height: 50px">
        </template>
      </el-table-column>
      <el-table-column
          prop="appName"
          :label="$t('APP名称')">
      </el-table-column>
      <el-table-column
          prop="identifier"
          :label="$t('包名')">
      </el-table-column>
      <el-table-column
          prop="account"
          :label="$t('账号')">
      </el-table-column>
      <el-table-column
          prop="disUrl"
          :label="$t('分发链接')">
        <template slot-scope="scope">
          <el-button style="display: block;margin: 0 auto"  @click="copy(scope.row.disUrl)"   type="text">复制地址</el-button>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="tfUrl"-->
<!--          :label="$t('tf官方链接')">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button style="display: block;margin: 0 auto"  @click="copy(scope.row.tfUrl)"    type="text">复制地址</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          :label="$t('今日下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.dayCount + '/' + scope.row.dayAndroidCount}}
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('昨日下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.lastDayCount + '/' + scope.row.lastAndroidDayCount}}
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('总下载(IOS/安卓)')" >
        <template slot-scope="scope">
          {{scope.row.sumCount + '/' + scope.row.sumAndroidCount}}
        </template>
      </el-table-column>
      <el-table-column
          prop="androidId"
          :label="$t('安卓')">
        <template slot-scope="scope">
          <span></span>
          <span v-if="scope.row.androidId !== 0  " type="text" size="small">{{$t('已上传')}}</span>
          <span v-if="scope.row.androidId === 0 " type="text" size="small">{{$t('未上传')}}</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="appleAccountId"
          :label="$t('IOS')">
        <template slot-scope="scope">
          <span v-if="scope.row.iosId !== 0  " type="text" size="small">{{$t('已上传')}}</span>
          <span v-if="scope.row.iosId === 0 " type="text" size="small">{{$t('未上传')}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="tfTestInfo"
          :label="$t('TF测试信息')">
      </el-table-column>
      <el-table-column
          :label="$t('IOS预备量')">
        <template slot-scope="scope">
          {{scope.row.tfEmail.length}}
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('创建时间')">
      </el-table-column>
      <el-table-column
          prop="updateTime"
          :label="$t('更新时间')">
      </el-table-column>
      <el-table-column
          :label="$t('状态')">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1" >{{$t('等待上传')}}</span>
          <span v-if="scope.row.status === 2" >{{$t('等待管理员审核')}}</span>
          <span v-if="scope.row.status === 3" >{{$t('上架中')}}</span>
          <span v-if="scope.row.status === 4" >{{$t('下架')}}</span>
          <span v-if="scope.row.status === 5" >{{$t('管理员下架')}}</span>
<!--          <span v-if="scope.row.status === 5" >{{$t('已删除')}}</span>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="lang"
          :label="$t('默认语言')">
      </el-table-column>
      <el-table-column
          prop="errMsg"
          :label="$t('错误信息')">
      </el-table-column>
      <el-table-column
          prop="adminRemark"
          :label="$t('管理员留言')">
      </el-table-column>
      <el-table-column
          fixed="right"
          :label="$t('操作')"
          width="180">
        <template slot-scope="scope">
          <el-button  type="text" @click="updateApp(scope.row)" size="small">{{$t('编辑')}}</el-button>
          <el-button type="text" @click="outputCert(scope.row)" size="small">{{$t('导出证书')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>
    <el-dialog
        :visible.sync="iosListDg"
        center
        width="60%" >
      <el-button  type="primary" @click="shuaxin()"  style="margin-bottom: 20px">{{$t('刷新')}}</el-button>
      <el-table
          :empty-text="$t('暂无数据')"
          stripe
          border
          header-cell-style="text-align:center"
          cell-style="text-align:center"
          :data="iosList"
          height="500"
      >

        <el-table-column
            prop="iosId"
            :label="$t('id')">
        </el-table-column>
        <el-table-column
            prop="version"
            :label="$t('版本')">
        </el-table-column>
        <el-table-column
            prop="buildVersion"
            :label="$t('构建版本')">
        </el-table-column>
        <el-table-column
            :label="$t('tf审核状态')">
          <template slot-scope="scope">
            <span v-if="'WAIT_UPLOAD_SUCCESS' === scope.row.tfReviewStatus" >{{$t('正在处理')}}</span>
            <span v-if="'PROCESSING' === scope.row.tfReviewStatus" >{{$t('正在处理')}}</span>
            <span v-if="'PROCESSING_EXCEPTION' === scope.row.tfReviewStatus" >{{$t('处理异常')}}</span>

            <span v-if="'READY_FOR_BETA_TESTING' === scope.row.tfReviewStatus" >{{$t('审核通过')}}</span>
            <span v-if="'IN_BETA_TESTING' === scope.row.tfReviewStatus" >{{$t('审核通过')}}</span>
            <span v-if="'BETA_APPROVED' === scope.row.tfReviewStatus" >{{$t('审核通过')}}</span>

            <span v-if="'READY_FOR_BETA_SUBMISSION' === scope.row.tfReviewStatus" >{{$t('准备提交')}}</span>
            <span v-if="'WAITING_FOR_BETA_REVIEW' === scope.row.tfReviewStatus" >{{$t('等待审核')}}</span>
            <span v-if="'IN_BETA_REVIEW' === scope.row.tfReviewStatus" >{{$t('正在审核')}}</span>
            <span v-if="'BETA_REJECTED' === scope.row.tfReviewStatus" >{{$t('审核拒绝')}}</span>
            <span v-if="'EXPIRED' === scope.row.tfReviewStatus" >{{$t('已过期')}}</span>
          </template>

        </el-table-column>
        <el-table-column
            :label="$t('是否线上版本')">
          <template slot-scope="scope">
            <span v-if="iosListId === scope.row.iosId" >{{$t('是')}}</span>
            <span v-if="iosListId !== scope.row.iosId">{{$t('否')}}</span>
          </template>
        </el-table-column>

        <el-table-column
            prop="testAccount"
            :label="$t('测试账号')">
        </el-table-column>
        <el-table-column
            prop="testPassword"
            :label="$t('测试密码')">
        </el-table-column>
        <el-table-column
            prop="testRemark"
            :label="$t('测试备注')">
        </el-table-column>
        <el-table-column
            prop="createTime"
            :label="$t('创建时间')">
        </el-table-column>
        <el-table-column
            prop="errMsg"
            :label="$t('审核错误消息')">
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px;color: red;">{{$t('PS: 等新版本通过tf审核后会自动替换为线上版本')}}</div>
    </el-dialog>
    <el-dialog
        :title="$t('编辑APP信息')"
        :visible.sync="updateAppVar.dialog"
        width="20%">

      <el-form ref="form"  label-width="100px">
        <el-form-item :label="$t('app名称')">
          <el-input :placeholder="$t('app名称')" v-model="updateAppVar.appName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('默认语言')">
          <el-input :placeholder="$t('默认语言')" v-model="updateAppVar.lang"></el-input>
        </el-form-item>
        <el-form-item :label="$t('管理员留言')">
          <el-input :placeholder="$t('管理员留言')" v-model="updateAppVar.adminRemark"></el-input>
        </el-form-item>
        <el-form-item label="状态">

          <el-select v-model="updateAppVar.status" placeholder="请选择" >
            <el-option
                v-for="item in updateAppVar.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>


      <el-button @click="updateAppVar.dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="updateApp('')">{{$t('确定')}}</el-button>
    </el-dialog>

  </div>
</template>

<script>
var that;
export default {
  name: 'UserList',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      dialog: false,
      dialog1: false,
      tableData: [],
      addamountData:{
        account: '',
        amount: 1
      },
      selectData: {
        scale: 0,
        id: '',
        account: ''
      },
      updateAppVar: {
        adminRemark: '',
        acId: '',
        appName: '',
        status: 2,
        errMsg: '',
        options: [{
          value: 2,
          label: '等待管理员审核'
        }, {
          value: 3,
          label: '上架中'
        }, {
          value: 4,
          label: '下架'
        }, {
          value: 5,
          label: '管理员下架'
        }
        ],
        lang: '',
        dialog: false
      },
      iosList:[

      ],
      iosListDg: false,
      iosListId: 0,
    }
  },
  beforeCreate() {

  },
  mounted() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    outputCert(data){
      that.$reqPost("/admin/outputCertNoReview?acId=" + data.acId,null).then(res=>{
        window.location.href = res.data.data
      })
    },
    shuaxin(){
      that.$reqPost("/tf_app_no_view/getIosList?acId=" + that.uploadIpaId,null).then(res=>{
        that.iosList = res.data.data
      })
    },
    closeReview(id){
      that.$reqPost("/tf_app_no_view/closeReview?id=" + id,null).then(res=>{
        that.$message.success(that.$t('撤销成功'))
        that.getInfo()
      })
    },
    copy(url){
      var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
      oInput.value = url;    //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success('复制成功');
    },
    catIosList(iosAppList,iosId,acId){
      that.iosListDg = true
      // that.iosList = iosAppList
      that.iosListId = iosId
      that.uploadIpaId = acId

      that.$reqPost("/tf_app_no_view/getIosList?acId=" + acId,null).then(res=>{
        that.iosList = res.data.data
      })

    },
    updateApp(data){
      if(data){
        that.updateAppVar.appName = data.appName
        that.updateAppVar.status = data.status
        that.updateAppVar.lang = data.lang
        that.updateAppVar.adminRemark = data.adminRemark
        that.updateAppVar.acId = data.acId
        that.updateAppVar.dialog = true
      }else {
        that.$reqPost("/admin/update_app_status_no_review?" + new URLSearchParams(that.updateAppVar).toString() ,null).then(res=>{
          that.$message.success(that.$t('修改成功'))
          that.getInfo()
          that.updateAppVar.dialog = false
        })
      }
    },
    updateScale(data){
      that.dialog1 = true
      that.selectData = data;
    },
    updateScaleOk(){
      that.$reqPost("/admin/setUserInvite?id=" + that.selectData.id + "&invite=" + that.selectData.scale ,{}).then(res=>{
        that.$message.success(that.$t('添加成功'))
        that.getInfo()
        that.dialog1 = false;
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/list_app_no_review",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    },
    addamount(dialog,account){
      if(dialog){
        that.dialog = dialog
        that.addamountData.account = account
      }else {
        that.$reqPost("/admin/addAmount",{account: that.addamountData.account,amount: that.addamountData.amount}).then(res=>{
          that.$message.success(that.$t('添加成功'))
          that.dialog = false
          that.getInfo()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
