<template>
  <div CLASS="box">
    <!--el-button style="float: right;margin-top: 20px;margin-right: 20px">返回首页</el-button-->
    <div class="login_box1" v-if="isLogin" >
      <!--    头像-->
      <div class="avatar_box">
        <img src="../assets/login.jpg">
      </div>
      <!--    表单-->
      <el-form ref="loginFromRef"  label-width="0px" class="login_from">
        <!--      用户名-->
        <el-form-item prop="account">
          <el-input @click="login"  prefix-icon="el-icon-s-custom"  :placeholder="$t('请输入账号')" v-model="account"></el-input>
        </el-form-item>
        <!--      密码-->
        <el-form-item prop="password">
          <el-input type="password" v-model="password"  :placeholder="$t('请输入密码')" prefix-icon="el-icon-s-cooperation"></el-input>
        </el-form-item>

        <!--    登录注册 -->
        <el-form-item class="btns1">
          <el-button type="primary" @click="login"  class="btns_log1">{{ $t('登录') }}</el-button>
          <el-button type="primary" @click="()=>{
                 this.isLogin = false
               }"  class="btns_reg1">{{ $t('注册') }}</el-button>
        </el-form-item>

      </el-form>
    </div>


    <div class="login_box" v-if="!isLogin" :style="isLoginV">
      <!--    头像-->
      <div class="avatar_box">
        <img src="../assets/login.jpg">
      </div>
      <!--    表单-->
      <el-form ref="loginFromRef"  label-width="0px" class="login_from">
        <!--      用户名-->
        <el-form-item prop="account">
          <el-input @click="login"  prefix-icon="el-icon-s-custom"  :placeholder="$t('请输入账号')" v-model="account"></el-input>
        </el-form-item>
        <!--      密码-->
        <el-form-item prop="password">
          <el-input type="password" v-model="password"  :placeholder="$t('请输入密码')" prefix-icon="el-icon-s-cooperation"></el-input>
        </el-form-item>

        <el-form-item prop="okPassword" >
          <el-input type="password" v-model="okPassword" :placeholder="$t('请确认密码')" prefix-icon="el-icon-s-cooperation"></el-input>
        </el-form-item>

        <el-form-item prop="code" >
          <el-input :placeholder="$t('验证码')" v-model="code" style="width: 200px;"></el-input>
          <el-image
              style="width: 100px; height: 40px;float:right;"
              :src="img" @click="getVer()"></el-image>
        </el-form-item>

        <div  @click="changeLogin" style="color: #8c939d;margin-bottom: 20px;margin-left: 210px">
          {{$t('已有账号_去登录')}}
        </div>

        <el-form-item class="btns" >
          <el-button type="primary" @click="register"  class="btns_reg">{{$t('注册')}}</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
var that
export default {
  name: 'Navigation',
  data() {
    return {
      okPassword: '',
      isLogin: true,
      uuid: '',
      code: '',
      img: '',
      account:'',
      password:'',
      activeName: 'first',
      invite:''
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    console.log("获取验证码")
    if (that.$route.query.hasOwnProperty('invite')) {
      that.invite = that.$route.query.invite
      that.isLogin = false
    }
    console.log(that.invite)
    that.getVer()
  },
  methods:{
    //获取验证码
    getVer(){
      console.log("获取验证码")
      that.$reqGet('/user/getCode',{account: that.account,password: that.password}).then(res=>{
        console.log("获取验证码",that.img)
        that.uuid = res.data.data.uuid
        that.img = res.data.data.img
      })
    },
    changeLogin(){
      if(this.isLogin){
        this.isLogin = !this.isLogin
      }else {
        this.isLogin = !this.isLogin
      }
    },
    login(){
      console.log("登录")

      that.$reqPost('/user/login',{account: that.account,password: that.password}).then(res=>{
        window.localStorage.setItem("token",res.data.data.token)
        window.localStorage.setItem("user",JSON.stringify(res.data.data.user))
        that.$router.push("/Home")
      })

    },
    register(){
      if(that.invite === ''){
        console.log("无验证码注册")
        if(that.account === '' || that.password === ''){
          this.$message(that.$t('账号和密码不能为空'));
        }else if(that.password !== that.okPassword){
          this.$message(that.$t('确认密码与密码不相同'));
        }else {
          that.$reqPost('/user/register',{account: that.account,password: that.password,uuid: that.uuid,code: that.code,inviteId: null}).then(res=>{
            console.log("注册成功")
            that.getVer()
            that.$message.success(that.$t('注册成功'))
            this.isLogin = true
            that.login();
          })
        }
      }else {
        console.log("有验证码注册")
        if(that.account === '' || that.password === ''){
          this.$message(that.$t('账号和密码不能为空'));
        }else if(that.password !== that.okPassword){
          this.$message(that.$t('确认密码与密码不相同'));
        }else {
          that.$reqPost('/user/register',{account: that.account,password: that.password,uuid: that.uuid,code: that.code,inviteId: that.invite}).then(res=>{
            console.log("注册成功")
            this.isLogin = true
            that.getVer()
            that.login();
            that.$message.success(that.$t('注册成功'))
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

.box{
  background: linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
  height: 100%;
  width: 100%;
}

.login_box {
  width: 450px;
  height: 470px;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 100px;
    width: 100px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #dddddd;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eeeeee;
    }
  }
}
.login_box1 {
  width: 450px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 100px;
    width: 100px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #dddddd;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eeeeee;
    }
  }
}
.login_from {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
}

.btns {
  margin-top: 30px;


  .btns_reg {
    width: 100%;

  }

  .btns_log {
    width: 100%;
  }
}


.btns1 {
  margin-top: 30px;
  margin-left: 15%;

  .btns_reg1 {
    width: 100px;
    margin-left: 60px;
  }

  .btns_log1 {
    width: 100px;
  }
}
</style>
