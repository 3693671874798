<template>
  <div >
    <el-button style="margin-right: 20px;margin-bottom: 20px" type="primary" @click="addMenu()">{{$t('新增')}}</el-button>
    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="pid"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="name"
          :label="$t('套餐名称')">
          <template slot-scope="scope">
            <span>{{$t( scope.row.name) }}</span>
          </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          :label="$t('套餐描述')">
          <template slot-scope="scope">
            <span>{{$t( scope.row.remark) }}</span>
          </template>
      </el-table-column>
      <el-table-column
          prop="amount"
          :label="$t('价格') + '/USDT'">
        <template slot-scope="scope">
          <span>{{scope.row.amount }}</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="serviceId"
          :label="$t('产品')">
        <template slot-scope="scope">
          <span>{{$t( menus[scope.row.serviceId -1 ].desc)}}</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="count"
          :label="$t('数量')">
        <template slot-scope="scope">
          <span>{{scope.row.count}}{{menus[scope.row.serviceId -1 ].type === 1?$t('分钟'):$t('个')}}</span>
        </template>
      </el-table-column>


      <el-table-column
          prop="status"
          :label="$t('状态')">
        <template slot-scope="scope">
          <span>{{scope.row.status === 1?$t('启用'):$t('停用')}}</span>
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('操作')">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="edit(scope.row)">{{$t('编辑')}}</el-button>
          <el-button type="danger" size="mini" @click="deleteMenu(scope.row.pid)">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


    <el-dialog :title="$t('新增套餐')" :visible.sync="dialog" :width="'30%'" :height="'80%'">
      <el-form :model="addBalanceData" label-width="100px">
        <el-form-item  :label="$t('产品')">
          <el-select v-model="addBalanceData.serviceId" :laceholder="$t('请选择')" style="width: 300px">
            <el-option
                v-for="item in menus"
                :key="item.id"
                :label="item.desc"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('套餐名称')" style="width: 400px">
          <el-input v-model="addBalanceData.name" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('套餐描述')"  label-width="100px" style="width: 400px" >
          <el-input v-model="addBalanceData.remark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('价格') + '/USDT'" style="width: 400px">
          <el-input v-model="addBalanceData.amount" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('数量')" style="width: 400px">
          <el-input v-model="addBalanceData.count"></el-input>
        </el-form-item>
        <el-form-item :label="$t('状态')"  style="width: 400px">
          <el-radio-group v-model="addBalanceData.status">
            <el-radio :label="1">{{$t('启用')}}</el-radio>
            <el-radio :label="0">{{$t('停用')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div>{{$t('说明： 如果产品类型为时间 则数量指的是分钟 如果产品类型为个数 则指的是几个')}}</div>
      </el-form>
      <!-- 弹窗内容 -->
      <div slot="footer" class="dialog-footer" style="text-align: center; margin-top: 0px;">
        <el-button type="primary" @click="addMenuOk" style="margin-right: 20px;">{{$t('确定')}}</el-button>
        <el-button @click="dialog = false">{{$t('取消')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('编辑套餐')" :visible.sync="dialog1" :width="'30%'" :height="'80%'">
      <el-form :model="EditBalanceData" label-width="100px">
        <el-form-item :label="$t('产品')">
          <el-select v-model="EditBalanceData.serviceId" :placeholder="$t('请选择')">
            <el-option
                v-for="item in menus"
                :key="item.id"
                :label="item.desc"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('套餐名称')" style="width: 400px">
          <el-input v-model="EditBalanceData.name" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('套餐描述')" style="width: 400px" >
          <el-input v-model="EditBalanceData.remark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('价格')  + '/USDT' " style="width: 400px">
          <el-input v-model="EditBalanceData.amount" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('数量')" style="width: 400px">
          <el-input v-model="EditBalanceData.count"></el-input>
        </el-form-item>

        <el-form-item :label="$t('状态')" style
            ="width: 400px">
          <el-radio-group v-model="EditBalanceData.status">
            <el-radio :label="1">{{$t('启用')}}</el-radio>
            <el-radio :label="0">{{$t('停用')}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!-- 弹窗内容 -->
      <div slot="footer" class="dialog-footer" style="text-align: center; margin-top: 0px;">
        <el-button type="primary" @click="editOk" style="margin-right: 20px;">{{$t('确定')}}</el-button>
        <el-button @click="dialog1 = false">{{$t('取消')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
var that;
export default {
  name: 'UserFlow',
  data() {
    return {
      menus:[

      ],
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      dialog: false,
      dialog1: false,
      tableData: [],
      addBalanceData:{
        serviceId: '',
        name: '',
        remark: '',
        amount: '',
        count: '',
        status: ''
      },
      EditBalanceData:{
        serviceId: '',
        name: '',
        remark: '',
        amount: '',
        count: '',
        status: '',
        pId: ''
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getMenuList();
    that.getInfo();
  },
  methods:{
    edit(data){
      that.EditBalanceData = data;
      that.EditBalanceData.amount = data.amount;
      that.dialog1 =true;
    },
    editOk(){
      that.$reqPost("/admin/updateAdminMenu",that.EditBalanceData).then(res=>{
        that.getInfo()
        that.dialog1 = false
      })
    },
    deleteMenu(id){
      that.$reqPost("/admin/deleteAdminMenu?id=" + id,{}).then(res=>{
        that.getInfo()
      })
    },
    addMenu(){
      that.dialog = true
    },
    addMenuOk(){
      that.$reqPost("/admin/addAdminMenu",that.addBalanceData).then(res=>{
        that.getInfo()
        that.dialog = false
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/getAdminMenuList",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    getMenuList(){
      that.$reqGet("/admin/getAdminMenu",{}).then(res=>{
        that.menus = res.data.data
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
