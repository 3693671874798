<template>
  <div id="app">
    <div id="language-selector" style="position: fixed; top: 30px; right: 30px;width: 100px">
      <el-select v-model="$i18n.locale" placeholder="select a language">
        <el-option label="English" value="en"></el-option>
        <el-option label="中文" value="zh"></el-option>
      </el-select>
    </div>

    <router-view></router-view>

  </div>
</template>

<script>
var isRefresh = false
var that
import { Option, Select, Tooltip } from 'element-ui'
import Driver from 'driver.js' // import driver.js
import 'driver.js/dist/driver.min.css' // import driver.js css

export default {
  created() {
    that = this
    let  lang = that.$getSession("lang")
    if(lang !== ''){
      isRefresh = false
      that.$i18n.locale = lang
      this.$nextTick(() => { // 在DOM更新之后再将isRefresh设为true
        isRefresh = true
      })
    }
  },
  methods:{
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    }
  },
  mounted() {
    // Check if the user has already seen the guide
    if (localStorage.getItem('hasSeenGuide')) {
      return
    }

    // create a new instance of Driver
    const driver = new Driver({
      animate: true, // Animate while changing highlighted element
      opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
      closeBtnText: that.$t('知道了')
    });

    driver.defineSteps([
      {
        element: '#language-selector',
        popover: {
          title: that.$t('语言选择'),
          description: that.$t('语言选择提示'),
          position: 'left',
        },
      },
    ]);

    // Start the introduction
    driver.start();

    // Set the flag in localStorage to indicate the user has seen the guide
    localStorage.setItem('hasSeenGuide', 'true');
  },
  data(){
    return{
      visible: true,

    }
  },
  name: 'app',
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if(newLocale !== oldLocale){
        that.$setSession("lang", newLocale)
        if(isRefresh){
          //刷新页面
          window.location.reload()
        }
      }
    }
  }
}
</script>

