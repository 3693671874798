<template>
  <div >
    <el-card class="box-card" style="width: 600px;height: 450px;">
      <div slot="header" class="clearfix">
        <span ><b>{{$t('推广信息')}}</b></span>

        <el-button type="primary" @click="dialog = true" style=" float: right;margin-bottom: 20px" >{{$t('申请提现')}}</el-button>
        <div style="margin-bottom: 20px"></div>
      </div>

      <div  class="text item">
        {{$t('推广链接')}} :<span style="margin-left: 10px">{{user.tgUrl}}</span>

        <el-button type="text" @click="copy(user.tgUrl) " style="margin-left: 10px"  >{{$t('复制链接')}}</el-button>
      </div>
      <div  class="text item" style="margin-top: 10px">
        {{$t('邀请人数')}} :<span style="margin-left: 10px;">{{user.yqCount}}</span>
      </div>
      <div  class="text item" style="margin-top: 20px">
        {{$t('总佣金')}} :<span style="margin-left: 10px;">{{user.userCommission.sumAmount}} USDT </span>
      </div>
      <div  class="text item" style="margin-top: 20px;">
        {{$t('可提现佣金')}} :<span style="margin-left: 10px;">{{user.userCommission.amount}} USDT</span>
      </div>
      <div  class="text item" style="margin-top: 20px;">
        {{$t('已提现佣金')}} :<span style="margin-left: 10px;">{{user.userCommission.outputAmount}} USDT</span>
      </div>
      <div  class="text item" style="margin-top: 20px;">
        {{$t('返佣比例')}} :<span style="margin-left: 10px;">{{user.scale}} %</span>
      </div>
      <div  class="text item" style="margin-top: 20px;">
        {{$t('联系客服')}} :<span style="margin-left: 10px;">{{tgInfo}}</span>
        <el-button type="text" @click="copy(tgInfo) " style="margin-left: 10px"  >{{$t('复制')}}</el-button>
      </div>


    </el-card>

    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('被邀请人账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" style="margin-top: 40px" @click="search()">{{$t('搜索')}}</el-button>

    <el-table

        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;margin-top: 20px">
      <el-table-column
          prop="reId"
          label="id">
      </el-table-column>
      <el-table-column
          prop="yqrAccount"
          :label="$t('邀请人')">
      </el-table-column>
      <el-table-column
          prop="byqAccount"
          :label="$t('被邀请人')">
      </el-table-column>
      <el-table-column
          prop="scale"
          :label="$t('邀请比例/%')">
      </el-table-column>
      <el-table-column
          prop="sumAmount"
          :label="$t('消费金额/USDT')">
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('消费时间')">
      </el-table-column>
      <el-table-column
          prop="amount"
          :label="$t('返佣金额') + '/USDT'">
        <template slot-scope="scope">
          <span>{{scope.row.amount }}</span>
        </template>
      </el-table-column>


    </el-table>

    <el-dialog
        :title="$t('请输入备注')"
        :visible.sync="dialog"
        width="20%">
      <span style="color: red">{{$t('请输入提现备注 如提现地址 TRC20地址')}}</span>
      <div>
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            :placeholder="$t('请输入提现备注')"
            v-model="withdrawData.remark"
            style="margin-top: 20px">
        </el-input>

      </div>
      <el-button @click="dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="withdrawApple()">{{$t('确定')}}</el-button>
    </el-dialog>


    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


  </div>
</template>

<script>
var that;
export default {
  name: 'UserFlow',
  data() {
    return {
      user: {
        id: '1',
        account: '1',
        createTime: '2',
        lastLoginTime: '2',
        amount: '0',
        type: '',
        lastLoginIp: '',
        tgUrl: '',
        yqCount: '',
        yqMoney: '',
        userCommission: {
          userId: 1,
          account: "admin",
          amount: 0,
          sumAmount: 0,
          outputAmount: 0
        }
      },
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      data:{
        url: '',
        inviteNum: 0,
      },
      dialog: false,
      tableData: [],
      addBalanceData:{
        account: '',
        balance: 1
      },
      tgInfo:'',
      withdrawData: {
        amount: '',
        remark: ''
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
    that.getUserInfo()
  },
  methods:{
    withdrawApple(){
      that.$reqPost('/user/withdraw?remark=' + that.withdrawData.remark ,'').then(res=>{
        that.$message.success(that.$t('申请成功'));
        that.dialog = false;
        that.getInfo();
        that.getUserInfo()
      })
    },
    getUserInfo(){
      that.$reqGet("/user/getUserInfo").then(res=>{
        that.user = res.data.data.user;
        that.tgInfo  =res.data.data.tgInfo;
      })
    },
    copy(url){
      var oInput = document.createElement('input'); //创建一个隐藏input（重要！）
      oInput.value = url;    //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success(that.$t('复制成功'));
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/user/getInviteAmountInfoList",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
