<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.userId" :placeholder="$t('用户id')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-button type="primary"  @click="getInfo()">{{$t('刷新列表')}}</el-button>
    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="downId"
          :label="$t('下载ID')">
      </el-table-column>
      <el-table-column
          prop="acId"
          :label="$t('appId')">
      </el-table-column>
      <el-table-column
          prop="userId"
          :label="$t('用户ID')">
      </el-table-column>
      <el-table-column
          prop="user.account"
          :label="$t('账号')">
      </el-table-column>
      <el-table-column
          prop="platform"
          :label="$t('平台')">
      </el-table-column>
      <el-table-column
          prop="downTime"
          :label="$t('下载时间')">
      </el-table-column>
      <el-table-column
          prop="ip"
          :label="$t('ip')">
      </el-table-column>

      <el-table-column
          prop="appName"
          :label="$t('app名称')">
      </el-table-column>

      <el-table-column
          prop="deCount"
          :label="$t('扣除台数')">
      </el-table-column>

    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


  </div>
</template>

<script>
var that;
export default {
  name: 'UserFlow',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: '',
        flowId: '',
        acId:'',
        userId:'',
      },
      dialog: false,
      tableData: [],
      addBalanceData:{
        account: '',
        balance: 1
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/list_down_record_no_review",{page:that.page.当前页,pageSize: that.page.页大小,userId: that.page.userId}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
