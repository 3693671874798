<template>
  <div>
    <el-card class="box-card" style="width: 800px">
      <div slot="header" class="clearfix">
        <span>{{$t('用户信息')}}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="save">{{$t('保存')}}</el-button>
      </div>
      <div  class="text item">
        {{$t('返利比例')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.返利比例" ></el-input>
      </div>

      <div  class="text item">
        {{$t('usdtApi')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.usdtApi" ></el-input>
      </div>

      <div  class="text item">
        {{$t('usdtAppid')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.usdtAppid" ></el-input>
      </div>

      <div  class="text item">
        {{$t('usdtKey')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.usdtKey" ></el-input>
      </div>

      <div  class="text item">
        {{$t('usdtNotifyDomain')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.usdtNotifyDomain" ></el-input>
      </div>

      <div  class="text item">
        {{$t('worldUrl')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.worldUrl" ></el-input>
      </div>

      <div  class="text item">
        {{$t('1台等于')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.public_scale" ></el-input> {{$t('USDT')}}
      </div>
      <div  class="text item">
        {{$t('下载默认语言')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.下载默认语言" ></el-input>
      </div>
      <div  class="text item">
        {{$t('安卓扣除台数')}} :<el-input style="width: 300px;margin-left: 20px;margin-bottom: 20px" v-model="settings.安卓扣除台数" ></el-input>
      </div>
    </el-card>
  </div>
</template>

<script>
var that;
export default {
  name: 'User',
  data() {
    return {
      settings: {
        返利比例: '返利比例',
        usdtApi: 'usdtApi',
        usdtAppid: 'usdtAppid',
        usdtKey: 'usdtKey',
        usdtNotifyDomain: 'usdtNotifyDomain',
        worldUrl: 'worldUrl',
        public_scale: 'public_scale',
        下载默认语言: 'zh',
        安卓扣除台数: 0,
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getSettings();
  },
  methods:{
    save(){
      that.$reqPost("/admin/setAdminSysSettings",that.settings).then(res=>{
        that.$message.success(that.$t('成功'))
      })
    },
    getSettings(){
      that.$reqGet("/admin/getAdminSysSettings").then(res=>{
        that.settings = res.data.data;
      })
    },
    getUserInfo(){
      that.$reqGet("/user/getUserInfo").then(res=>{
        that.user = res.data.data.user;
        window.localStorage.setItem("user",JSON.stringify(res.data.data.user))
        if(that.user.type === 0){
          that.user.type = that.$t('普通用户')
        }else {
          that.user.type = that.$t('管理员')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

</style>
