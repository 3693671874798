<template>
        <el-container style="height: 100%;"  >
              <el-dialog
                  :title="$t('系统公告')"
                  :visible.sync="dialogVisible"
                  :show-close="false"
                  width="30%">
                <div class="scrollable-content">
                  <span>{{$t('公告内容')}}</span>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="noOk()">{{$t('不同意')}}</el-button>
                 <el-button type="primary" @click="ok()">{{$t('我同意')}}</el-button>
                </span>
              </el-dialog>
            <el-header style="background-color: white;height: 80px" class="brd">
                <div>
                   <el-avatar size="large" :src="avatarURL"></el-avatar>
                  <span style="color: black" >{{$t('网站名称')}}</span>

                </div>
            </el-header>

            <el-container class="brd" style="margin-top: 2px">
                <el-aside width="230px" style="background-color: white" class="brd">

                    <el-menu
                            unique-opened
                            :default-active="$route.path"
                            class="el-menu-vertical-demo"
                            >
                      <template  v-for="(value, index) in data ">

                        <el-menu-item :index="value.path" @click="toPath(value.path)"  v-if="处理0(value.type,value.admin)">
                          <i :class="value.icon"></i>
                          <span slot="title">{{$t( value.name)}}</span>
                        </el-menu-item>

                        <el-submenu  v-if="处理1(value.type,value.admin)" :index="index" >
                          <template slot="title">
                            <i :class="value.icon"></i>
                            <span>{{$t( value.name)}}</span>
                          </template>
                          <el-menu-item v-if="处理2(value.admin)" @click="toPath(value.path)" v-for="(value, index)  in value.item" :index="value.path">{{$t( value.name)}}</el-menu-item>
                        </el-submenu>

                      </template>

                     </el-menu>
                </el-aside>

                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>

        </el-container>
</template>

<script>
    var that;
    import avatarURL from '../assets/login.jpg'
    export default {
      name: "Home",
      methods:{
        ok(){
          that.$setSession("ag","ag")
          that.dialogVisible = false
        },
        noOk(){
          that.$router.push("/Login")
        },
        getService(){
          // that.$reqGet("/serviceInfo/search",{page: 1,pageSize: 500}).then(res=>{
          //   that.tableData = res.data.data.data
          //   for (let i = 0; i < that.tableData.length ; i++) {
          //     const  data =  that.tableData[i]
          //     const  s = [];
          //     s.path = data.vuePath
          //     s.admin = false
          //     s.name = data.name
          //   }
          // })
        },
        处理0(type,admin){

          if(type === 0){
            if(admin){
              if(this.user.account === "admin"){
                return  true
              }else {
                return false
              }
            }else {
              return true
            }
          }else {
            return  false
          }

        },
        处理1(type,admin){
          if(type === 1){
            if(admin){
              if(this.user.account === "admin"){
                return  true
              }else {
                return false
              }
            }else {
              return true
            }
          }else {
            return  false
          }
        },
        处理2(admin){
          if(admin){
            if(user.account === "admin"){
              return  true
            }else {
              return false
            }
          }else {
            return true
          }

        },
        toPath(path){
          that.$router.push(path)
        }
      },
        created() {
          that = this
          this.user = JSON.parse(that.$getSession("user"));
          console.log("哈哈哈")
          let  ok = that.$getSession("ag")
          if(ok === ''){
             that.dialogVisible = true;
          }
          that.getService();
        },
        data(){
            return{
                dialogVisible: false,
                tableData: [],
                user: {},
                avatarURL: avatarURL,
                data:[
                  {type: 0,icon: "el-icon-monitor",name: "控制台",path: "/User",admin: false},
                    //type 0为非下拉菜单  1为下拉菜单有子元素
                  {type: 1,icon: "el-icon-s-tools",name: "管理员操作",admin: true,item: [
                      {path: "/AdminUserList",name: "用户列表",admin: false},
                      {path: "/AdminUserFlow",name: "用户流水",admin: false},
                      {path: "/AdminUserOrderRecord",name: "充值订单",admin: false},
                      {path: "/AdminI18n",name: "国际化",admin: false},
                      {path: "/AdminSystemSettings",name: '系统设置',admin: false},
                      {path: "/AdminUserInivte",name: '推广记录',admin: false},
                      {path: "/AdminWithdrawApple",name: '提现申请',admin: false},
                    ]},
                  //type 0为非下拉菜单  1为下拉菜单有子元素
                  {type: 1,icon: "el-icon-s-order",name: "管理APP",admin: true,item: [
                      {path: "/AdminAppleAccountList",name: '苹果账号',admin: false},
                      {path: "/AdminUserAppList",name: '用户app',admin: false},
                      {path: "/AdminAppDownRecord",name: '下载记录',admin: false},
                      {path: "/AdminUserAppListReview",name: '用户app免审',admin: false},
                      {path: "/AdminAppDownRecordReview",name: '下载记录免审',admin: false},

                    ]},
                  {type: 1,icon: "el-icon-user",admin: false,name: "用户信息",item: [
                      {path: "/UserFlow",name: "流水信息",admin: false},
                      {path: "/OrderRecord",name: "充值订单",admin: false},
                      {path: "/BuyAmount",name: "充值余额",admin: false},
                    ]},
                  {type: 1,icon: "el-icon-menu",admin: false,name: "我的APP",item: [
                      {path: "/UserAppList",name: "APP列表",admin: false},
                      {path: "/AppDownRecord",name: "下载记录",admin: false},
                    ]},
                  {type: 1,icon: "el-icon-s-fold",admin: false,name: "我的APP免审",item: [
                      {path: "/UserAppListNoReview",name: "APP列表",admin: false},
                      {path: "/AppDownRecordNoReview",name: "下载记录",admin: false},
                    ]},
                  {type: 1,icon: "el-icon-share",admin: false,name: "推介计划",item: [
                      {path: "InviteList",admin: false,name: "推广记录"},
                      {path: "InviteAmountList",admin: false,name: "分佣记录"},
                      {path: "WithdrawApple",admin: false,name: "提现记录"}
                    ]},
                  {type: 0,icon: "el-icon-document",name: "使用文档",path: "/UseWorld",admin: false},
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
.scrollable-content {
  height: 200px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 3px;  /* 或者其它你想要的宽度 */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid #e1e5e8;
}
.el-header{
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color: #dddddd;
    font-size: 20px;
    > div{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            margin-left: 15px;
        }
        img{
            height: 100%;
        }
    }
}
.el-aside{
    background-color: #333744;
    .el-menu{
        border-right: none;
    }
}
.el-man{
    background-color: #eaedf1;
}

.brd{
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
</style>
