<template>
  <div>
      测试
  </div>
</template>

<script>
var that;
export default {
  name: 'Test',
  data() {
    return {

    }
  },
  beforeCreate() {

  },
  created() {
    that = this
  },
  methods:{
  }
}
</script>

<style lang="less" scoped>



</style>
