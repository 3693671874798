<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{$t('用户信息')}}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="quit">{{$t('退出')}}</el-button>
        <el-button style="float: right; padding: 3px 0;padding-right: 10px" @click="updatePassword(true)" type="text">{{$t('修改密码')}}</el-button>
        <el-button style="float: right; padding: 3px 0;padding-right: 10px" @click="dialog2 = true" type="text">{{$t('兑换台数')}}</el-button>
      </div>
      <div  class="text item">
        {{$t('账号')}} :<span style="margin-left: 10px">{{user.account}}</span>
      </div>
      <div  class="text item">
        {{$t('台数')}} :<span style="margin-left: 10px">{{user.dcount}}</span>

      </div>
      <div  class="text item">
        {{$t('用户类型')}} :<span style="margin-left: 10px">{{user.type}}</span>
      </div>
      <div  class="text item">
        {{$t('余额')}}:<span style="margin-left: 10px">{{user.amount}} USDT</span>
      </div>
      <div  class="text item">
        {{$t('用户id')}} :<span style="margin-left: 10px">{{user.id}}</span>
      </div>
      <div  class="text item">
        {{$t('注册时间')}} :<span style="margin-left: 10px">{{user.createTime}}</span>
      </div>
      <div  class="text item">
        {{$t('最后登录时间')}}:<span style="margin-left: 10px">{{user.lastLoginTime}}</span>
      </div>
      <div  class="text item">
        {{$t('最后登录IP')}}:<span style="margin-left: 10px">{{user.lastLoginIp}}</span>
      </div>
<!--      <div  class="text item">-->
<!--        {{$t('推广链接')}}:<span style="margin-left: 10px">{{user.tgUrl}}</span>-->
<!--      </div>-->
<!--      <div  class="text item">-->
<!--        {{$t('邀请人数')}}:<span style="margin-left: 10px">{{user.yqCount}}</span>-->
<!--      </div>-->
<!--      <div  class="text item">-->
<!--        {{$t('佣金分成')}}:<span style="margin-left: 10px">{{user.yqMoney}} USDT</span>-->
<!--      </div>-->
<!--      <div  class="text item">-->
<!--        {{$t('佣金分成比例')}} :<span style="margin-left: 10px">{{user.scale}}%</span>-->
<!--      </div>-->

    </el-card>
    <el-dialog
        :title="$t('提示')"
        :visible.sync="dialog"
        width="20%">
      <el-input :placeholder="$t('请输入原密码')" v-model="upPassword.password"></el-input>
      <el-input :placeholder="$t('请输入新密码')" v-model="upPassword.newPassword" style="margin-top: 10px"></el-input>
      <el-button @click="dialog = false" style="margin-top: 30px">{{$t('取消')}}</el-button>
      <el-button type="primary" @click="updatePassword(false)">{{$t('确定')}}</el-button>
    </el-dialog>

    <el-dialog
        :visible.sync="dialog2"
        width="30%">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{$t('兑换中心')}}</span>
        </div>
        <div class="text item">
          {{$t('当前余额')}}:  {{user.amount}} USDT
        </div>
        <div class="text item">
          {{$t('当前台数')}}:  {{user.dcount}} {{$t('台')}}
        </div>
        <div class="text item">
          {{$t('兑换比例')}}:  1 台 = {{user.public_scale}} {{$t('U')}}
        </div>
        <div class="text item">
          {{$t('兑换台数')}}:  <el-input-number style="margin-left: 10px"  v-model="buyCount"  :min="1" :max="10000" :label="$t('请输入')"></el-input-number> {{$t('台')}}
        </div>
        <div class="text item">
          {{$t('总价')}}:  {{buyCount * user.public_scale}} USDT
        </div>
        <el-button style="margin-top: 10px" type="primary" @click="buy()">{{$t('立即兑换')}}</el-button>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
var that;
export default {
  name: 'User',
  data() {
    return {
      user: {
        id: '1',
        account: '1',
        createTime: '2',
        lastLoginTime: '2',
        amount: '0',
        type: '',
        lastLoginIp: '',
        tgUrl: '',
        yqCount: '',
        yqMoney: '',
        dcount:'',
        public_scale:1,
      },
      dialog: false,
      dialog2: false,
      buyCount: 1,
      upPassword:{
        password: '',
        newPassword: ''
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getUserInfo();
  },
  methods:{
    buy(){
      that.$reqPost("/order/buy_count?num="+ that.buyCount,{}).then(res=>{
        that.$message.success(that.$t('兑换成功'))
        that.getUserInfo();
        that.dialog2 = false
      })
    },
    getUserInfo(){
      that.$reqGet("/user/getUserInfo").then(res=>{
        that.user = res.data.data.user;
        window.localStorage.setItem("user",JSON.stringify(res.data.data.user))
        if(that.user.type === 0){
          that.user.type = that.$t('普通用户')
        }else {
          that.user.type = that.$t('管理员')
        }
      })
    },
    quit(){
      that.$reqGet("/user/quit").then(res=>{
        that.$message.success( that.$t('退出成功'))
        that.$router.push("/Login")
      })
    },
    updatePassword(dialog){
      if(dialog){
        that.dialog = dialog
      }else {
        that.$reqPost("/user/updatePassword",that.upPassword).then(res=>{
          that.$message.success(that.$t('修改成功_请重新登录'))
          that.dialog = false
          that.$router.push("/Login")
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

</style>
