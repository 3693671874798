<template>
  <div >
    <el-input style="width: 200px;margin-right: 20px;margin-bottom: 20px" v-model="page.account" :placeholder="$t('账号')"></el-input>
    <el-button type="primary" icon="el-icon-search" @click="search()">{{$t('搜索')}}</el-button>
    <el-table
        :empty-text="$t('暂无数据')"
        stripe
        border
        header-cell-style="text-align:center"
        cell-style="text-align:center"
        :data="tableData"
        style="width: 100%;">
      <el-table-column
          prop="flowId"
          :label="$t('流水id')">
      </el-table-column>
      <el-table-column
          prop="account"
          :label="$t('账号')">
      </el-table-column>
      <el-table-column
          :label="$t('类型')">
        <template slot-scope="scope">
            <span>{{scope.row.type === 1?$t('收入'):$t('支出')}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="amount"
          :label="$t('金额') + '/USDT'">
        <template slot-scope="scope">
          <span>{{scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          :label="$t('创建时间')">
      </el-table-column>
      <el-table-column
          prop="exchangeDesc"
          :label="$t('说明')">
      </el-table-column>

    </el-table>

    <el-pagination
        style="text-align: center;margin-top: 30px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.当前页"
        :page-sizes="[5, 40, 80, 100]"
        :page-size="page.页大小"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.总数">
    </el-pagination>


  </div>
</template>

<script>
var that;
export default {
  name: 'UserFlow',
  data() {
    return {
      page:{
        页大小: 5,
        当前页: 1,
        总数: 1,
        account: ''
      },
      dialog: false,
      tableData: [],
      addBalanceData:{
        account: '',
        balance: 1
      }
    }
  },
  beforeCreate() {

  },
  created() {
    that = this
    that.getInfo();
  },
  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      that.page.页大小 = val
      that.getInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      that.page.当前页 = val
      that.getInfo()
    },
    getInfo(){
      that.$reqGet("/admin/getUserFlow",{page:that.page.当前页,pageSize: that.page.页大小,account: that.page.account}).then(res=>{
        that.tableData = res.data.data.data
        that.page.总数 = res.data.data.total
      })
    },
    search(){
      that.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>

.el-table-column{
  align-content: center;
}

</style>
